
const IMAGES=[
{img:require("./carousel-01_1x.jpg")},
{img:require("./carousel-02_1x.jpg")},
{img:require("./carousel-03_1x.jpg")},
{img:require("./carousel-04_1x.jpg")},
{img:require("./carousel-05_1x.jpg")},
]
    


export default IMAGES;