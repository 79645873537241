import React from "react";
import "./Table.css";
export default function Table() {
  return (
    <div className="Wrap--light snipcss-2pINs snip-div">
      <section
        className="Container Container--light Comparison snip-section"
        id="comparison-section"
      >
        <header>
          <h2 className="Typo--h2 snip-h2">
            Sleep Better-Faster-Sounder With Dodow
          </h2>
        </header>
        <table
          className="Comparison--table Typo--table"
          cellSpacing="0"
          cellPadding="0"
        >
          <thead>
            <tr className="Comparison--row-header">
              <td></td>
              <th scope="flex-row" className="Typo--table-header">
                Dodow
              </th>
              <th scope="flex-row" className="Typo--table-header">
                Sleep therapies
              </th>
              <th scope="flex-row" className="Typo--table-header">
                Sleeping pills
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="Comparison--row">
              <th scope="flex-row" className="Typo--table-header">
                <div className="flex-row snip-div">
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_natural_grey.svg"
                    alt=""
                    className="snip-img"
                  />
                  <span>100% natural</span>
                </div>
              </th>
              <td className="Comparison--col Comparison--col-icon">
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_yes.svg"
                  className="Comparison--icon-regular snip-img"
                  alt="Yes"
                />
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_yes_b.svg"
                  className="Comparison--icon-alt snip-img"
                  alt="Yes"
                />
              </td>
              <td className="Comparison--col Comparison--col-price">
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_yes.svg"
                  className="Comparison--icon-regular snip-img"
                  alt="Yes"
                />
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_yes_b.svg"
                  className="Comparison--icon-alt snip-img"
                  alt="Yes"
                />
              </td>
              <td className="Comparison--col Comparison--col-icon">
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_no.svg"
                  className="Comparison--icon-regular snip-img"
                  alt="No"
                />
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_no_b.svg"
                  className="Comparison--icon-alt snip-img"
                  alt="No"
                />
              </td>
            </tr>
            <tr className="Comparison--row">
              <th scope="flex-row" className="Typo--table-header">
                <div className="flex-row snip-div">
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_addiction_grey.svg"
                    alt=""
                    className="snip-img"
                  />
                  <span>No addiction</span>
                </div>
              </th>
              <td className="Comparison--col Comparison--col-icon">
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_yes.svg"
                  className="Comparison--icon-regular snip-img"
                  alt="Yes"
                />
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_yes_b.svg"
                  className="Comparison--icon-alt snip-img"
                  alt="Yes"
                />
              </td>
              <td className="Comparison--col Comparison--col-price">
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_yes.svg"
                  className="Comparison--icon-regular snip-img"
                  alt="Yes"
                />
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_yes_b.svg"
                  className="Comparison--icon-alt snip-img"
                  alt="Yes"
                />
              </td>
              <td className="Comparison--col Comparison--col-icon">
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_no.svg"
                  className="Comparison--icon-regular snip-img"
                  alt="No"
                />
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_no_b.svg"
                  className="Comparison--icon-alt snip-img"
                  alt="No"
                />
              </td>
            </tr>
            <tr className="Comparison--row">
              <th scope="flex-row" className="Typo--table-header">
                <div className="flex-row snip-div">
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_long_term_grey.svg"
                    alt=""
                    className="snip-img"
                  />
                  <span>Long-term solution</span>
                </div>
              </th>
              <td className="Comparison--col Comparison--col-icon">
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_yes.svg"
                  className="Comparison--icon-regular snip-img"
                  alt="Yes"
                />
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_yes_b.svg"
                  className="Comparison--icon-alt snip-img"
                  alt="Yes"
                />
              </td>
              <td className="Comparison--col Comparison--col-price">
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_yes.svg"
                  className="Comparison--icon-regular snip-img"
                  alt="Yes"
                />
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_yes_b.svg"
                  className="Comparison--icon-alt snip-img"
                  alt="Yes"
                />
              </td>
              <td className="Comparison--col Comparison--col-icon">
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_no.svg"
                  className="Comparison--icon-regular snip-img"
                  alt="No"
                />
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_no_b.svg"
                  className="Comparison--icon-alt snip-img"
                  alt="No"
                />
              </td>
            </tr>
            <tr className="Comparison--row">
              <th scope="flex-row" className="Typo--table-header">
                <div className="flex-row snip-div">
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_refund_grey.svg"
                    alt=""
                    className="snip-img"
                  />
                  <span>Satisfied or refunded</span>
                </div>
              </th>
              <td className="Comparison--col Comparison--col-price">
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_yes.svg"
                  className="Comparison--icon-regular snip-img"
                  alt="Yes"
                />
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_yes_b.svg"
                  className="Comparison--icon-alt snip-img"
                  alt="Yes"
                />
              </td>
              <td className="Comparison--col Comparison--col-icon">
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_no.svg"
                  className="Comparison--icon-regular snip-img"
                  alt="No"
                />
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_no_b.svg"
                  className="Comparison--icon-alt snip-img"
                  alt="No"
                />
              </td>
              <td className="Comparison--col Comparison--col-icon">
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_no.svg"
                  className="Comparison--icon-regular snip-img"
                  alt="No"
                />
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_no_b.svg"
                  className="Comparison--icon-alt snip-img"
                  alt="No"
                />
              </td>
            </tr>
            <tr className="Comparison--row">
              <th scope="flex-row" className="Typo--table-header">
                <div className="flex-row snip-div">
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_sleep_platform_grey.svg"
                    alt=""
                    className="snip-img"
                  />
                  <span>Personalized advice</span>
                </div>
              </th>
              <td className="Comparison--col Comparison--col-price">
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_yes.svg"
                  className="Comparison--icon-regular snip-img"
                  alt="Yes"
                />
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_yes_b.svg"
                  className="Comparison--icon-alt snip-img"
                  alt="Yes"
                />
              </td>
              <td className="Comparison--col Comparison--col-icon">
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_no.svg"
                  className="Comparison--icon-regular snip-img"
                  alt="No"
                />
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_no_b.svg"
                  className="Comparison--icon-alt snip-img"
                  alt="No"
                />
              </td>
              <td className="Comparison--col Comparison--col-icon">
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_no.svg"
                  className="Comparison--icon-regular snip-img"
                  alt="No"
                />
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_no_b.svg"
                  className="Comparison--icon-alt snip-img"
                  alt="No"
                />
              </td>
            </tr>
            <tr className="Comparison--row">
              <th scope="flex-row" className="Typo--table-header">
                <div className="flex-row snip-div">
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_1time_purchase_grey.svg"
                    alt=""
                    className="snip-img"
                  />
                  <span>One-time purchase</span>
                </div>
              </th>
              <td className="Comparison--col Comparison--col-price">
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_yes.svg"
                  className="Comparison--icon-regular snip-img"
                  alt="Yes"
                />
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_yes_b.svg"
                  className="Comparison--icon-alt snip-img"
                  alt="Yes"
                />
              </td>
              <td className="Comparison--col Comparison--col-icon">
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_no.svg"
                  className="Comparison--icon-regular snip-img"
                  alt="No"
                />
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_no_b.svg"
                  className="Comparison--icon-alt snip-img"
                  alt="No"
                />
              </td>
              <td className="Comparison--col Comparison--col-icon">
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_no.svg"
                  className="Comparison--icon-regular snip-img"
                  alt="No"
                />
                <img
                  src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_no_b.svg"
                  className="Comparison--icon-alt snip-img"
                  alt="No"
                />
              </td>
            </tr>
            <tr className="Comparison--row">
              <th scope="flex-row" className="Typo--table-header">
                <div className="flex-row snip-div">
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_price_tag_grey.svg"
                    alt=""
                    className="snip-img"
                  />
                  <span>Price</span>
                </div>
              </th>
              <td className="Comparison--col Comparison--col-icon">$59 for life</td>
              <td className="Comparison--col Comparison--col-icon">$150 /month</td>
              <td className="Comparison--col Comparison--col-icon">$85 /month</td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  );
}
