import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import boostrap from "bootstrap/dist/js/bootstrap.bundle.min";
import Footer from "./components/footer/Footer";
import Created from "./components/created/Created";
import Comments from "./components/comments/Comments";
import Slider from "./components/slider/Slider";
import Questions from "./components/questions/Questions";
import Table from "./components/table/Table";
import Easy from "./components/Easy/Easy";
import FourthSection from "./components/Fourthsection/FourthSection";
import Users from "./components/No_users/Users";
import First from "./components/firstsection/First";
import Second from "./components/secondSection/Second";
import Third from "./components/Third/Third";
import Displayprod from "./components/displayproduct/Displayprod";
import Second_test from "./components/secondSection/Second_test";
import Carousel_img from "./components/carousel/Carouse_img";

function App() {

  return (
    <div>
      <First />
      <Second_test/>
      <Third />
      <Easy />
      <FourthSection />
      <Users />
      <Displayprod />
      <Table />
      <Questions />
      <Carousel_img/>
      <Comments />
      <Created />
      <Footer />


    </div>
  );
}

export default App;
