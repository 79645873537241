import React, {useRef} from "react";
import "./fourth.css";



function FourthSection() {

  const card_model = useRef(null);


  const card_toggle=(id)=>{

    document.getElementsByTagName("html")[0].style.overflow!=="hidden" ?  document.getElementsByTagName("html")[0].style.overflow="hidden" : document.getElementsByTagName("html")[0].style.overflow="auto";
    card_model.current.style.display=="block" ? card_model.current.style.display = "none" : card_model.current.style.display = "block";
    card_model.current.children[id].classList.toggle("hidden");
  }


  return (
    <div>
      <div className="Wrap--light snipcss-1fyKA snip-div" id="cards">
        <section className="Cards flex-col Container snip-section">
          <header>
            <h2 className="Typo--h2 snip-h2">
              Dodow is suitable for the whole family, from the age of 6
            </h2>
          </header>
          <div className="Cards--content snip-div">
            <div className="Cards--content-item flex-col card-1 snip-div" onClick={()=>card_toggle(0)}>
              <div className="Cards--content-item-wrap flex-col card-1 snip-div"></div>
              <div className="Cards--content-item-title-wrap snip-div">
                <h3 className="Typo--h3 snip-h3">Night awakenings</h3>
                <button
                  className="Cards--content-item-desktop-btn   "
                  aria-label="See more"
                >
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_circled_arrow_white.svg"
                    alt=""
                    loading="lazy"
                    className="snip-img"
                  />
                </button>
              </div>
              <div className="Cards--content-item-text snip-div">
                <div className="Cards--content-item-flex snip-div">
                  <figure>
                    <figcaption className="Testimonial">
                      <span className="Testimonial--img Typo--body-small-bold">
                        FD
                      </span>
                      <strong className="Testimonial--name Typo--body-small-bold">
                        Frederic D.
                      </strong>
                      <small className="Testimonial--source Typo--body-small">
                        May 2, 2019 — Amazon
                      </small>
                    </figcaption>
                    <blockquote className="Typo--body-large">
                      In a few minutes... I am back to sleep... whereas
                      previously that took me more than 1 hour.
                    </blockquote>
                  </figure>
                </div>
                <button className="Cards--content-item-more-btn 0 Button Typo--button flex-row   ">
                  See more
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_angled_caret_right_white.svg"
                    alt=""
                    loading="lazy"
                    className="snip-img"
                  />
                </button>
              </div>
            </div>
            <div className="Cards--content-item flex-col card-2 snip-div" onClick={()=>card_toggle(1)}>
              <div className="Cards--content-item-wrap flex-col card-2 snip-div"></div>
              <div className="Cards--content-item-title-wrap snip-div">
                <h3 className="Typo--h3 snip-h3">Stress</h3>
                <button
                  className="Cards--content-item-desktop-btn   "
                  aria-label="See more"
                >
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_circled_arrow_white.svg"
                    alt=""
                    loading="lazy"
                    className="snip-img"
                  />
                </button>
              </div>
              <div className="Cards--content-item-text snip-div">
                <div className="Cards--content-item-flex snip-div">
                  <figure>
                    <figcaption className="Testimonial">
                      <span className="Testimonial--img Typo--body-small-bold">
                        MG
                      </span>
                      <strong className="Testimonial--name Typo--body-small-bold">
                        Mor Gane
                      </strong>
                      <small className="Testimonial--source Typo--body-small">
                        February 26, 2019 — Facebook
                      </small>
                    </figcaption>
                    <blockquote className="Typo--body-large">
                      I had a lot of trouble falling asleep [...] I was
                      persuaded, because it did a great job!
                    </blockquote>
                  </figure>
                </div>
                <button className="Cards--content-item-more-btn 0 Button Typo--button flex-row   ">
                  See more
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_angled_caret_right_white.svg"
                    alt=""
                    loading="lazy"
                    className="snip-img"
                  />
                </button>
              </div>
            </div>
            <div className="Cards--content-item flex-col card-3 snip-div" onClick={()=>card_toggle(2)}>
              <div className="Cards--content-item-wrap flex-col card-3 snip-div"></div>
              <div className="Cards--content-item-title-wrap snip-div">
                <h3 className="Typo--h3 snip-h3">
                  Thoughts running through your head
                </h3>
                <button
                  className="Cards--content-item-desktop-btn   "
                  aria-label="See more"
                >
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_circled_arrow_white.svg"
                    alt=""
                    loading="lazy"
                    className="snip-img"
                  />
                </button>
              </div>
              <div className="Cards--content-item-text snip-div">
                <div className="Cards--content-item-flex snip-div">
                  <figure>
                    <figcaption className="Testimonial">
                      <span className="Testimonial--img Typo--body-small-bold">
                        LC
                      </span>
                      <strong className="Testimonial--name Typo--body-small-bold">
                        Laure C.
                      </strong>
                      <small className="Testimonial--source Typo--body-small">
                        June 5, 2018 — Facebook
                      </small>
                    </figcaption>
                    <blockquote className="Typo--body-large">
                      Some nights my brain is more active [...] but Dodow helps
                      reduce that time enormously!
                    </blockquote>
                  </figure>
                </div>
                <button className="Cards--content-item-more-btn 0 Button Typo--button flex-row   ">
                  See more
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_angled_caret_right_white.svg"
                    alt=""
                    loading="lazy"
                    className="snip-img"
                  />
                </button>
              </div>
            </div>
            <div className="Cards--content-item flex-col card-4 snip-div" onClick={()=>card_toggle(3)}>
              <div className="Cards--content-item-wrap flex-col card-4 snip-div"></div>
              <div className="Cards--content-item-title-wrap snip-div">
                <h3 className="Typo--h3 snip-h3">Chronic insomnia</h3>
                <button
                  className="Cards--content-item-desktop-btn   "
                  aria-label="See more"
                >
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_circled_arrow_white.svg"
                    alt=""
                    loading="lazy"
                    className="snip-img"
                  />
                </button>
              </div>
              <div className="Cards--content-item-text snip-div">
                <div className="Cards--content-item-flex snip-div">
                  <figure>
                    <figcaption className="Testimonial">
                      <span className="Testimonial--img Typo--body-small-bold">
                        LC
                      </span>
                      <strong className="Testimonial--name Typo--body-small-bold">
                        Lauranne C.
                      </strong>
                      <small className="Testimonial--source Typo--body-small">
                        May 28, 2018 — Facebook
                      </small>
                    </figcaption>
                    <blockquote className="Typo--body-large">
                      I fell asleep like a log, without even realizing it!
                    </blockquote>
                  </figure>
                </div>
                <button className="Cards--content-item-more-btn 0 Button Typo--button flex-row   ">
                  See more
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_angled_caret_right_white.svg"
                    alt=""
                    loading="lazy"
                    className="snip-img"
                  />
                </button>
              </div>
            </div>
            <div className="Cards--content-item flex-col card-5 snip-div" onClick={()=>card_toggle(4)}>
              <div className="Cards--content-item-wrap flex-col card-5 snip-div"></div>
              <div className="Cards--content-item-title-wrap snip-div">
                <h3 className="Typo--h3 snip-h3">Worries</h3>
                <button
                  className="Cards--content-item-desktop-btn   "
                  aria-label="See more"
                >
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_circled_arrow_white.svg"
                    alt=""
                    loading="lazy"
                    className="snip-img"
                  />
                </button>
              </div>
              <div className="Cards--content-item-text snip-div">
                <div className="Cards--content-item-flex snip-div">
                  <figure>
                    <figcaption className="Testimonial">
                      <span className="Testimonial--img Typo--body-small-bold">
                        CP
                      </span>
                      <strong className="Testimonial--name Typo--body-small-bold">
                        Cindy P.
                      </strong>
                      <small className="Testimonial--source Typo--body-small">
                        January 19, 2019 — Facebook
                      </small>
                    </figcaption>
                    <blockquote className="Typo--body-large">
                      I've been taking sleeping pills for 10 years [...] and
                      now, at night, I need no sleeping pills.
                    </blockquote>
                  </figure>
                </div>
                <button className="Cards--content-item-more-btn 0 Button Typo--button flex-row   ">
                  See more
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_angled_caret_right_white.svg"
                    alt=""
                    loading="lazy"
                    className="snip-img"
                  />
                </button>
              </div>
            </div>
            <div className="Cards--content-item flex-col card-6 snip-div" onClick={()=>card_toggle(5)}>
              <div className="Cards--content-item-wrap flex-col card-6 snip-div"></div>
              <div className="Cards--content-item-title-wrap snip-div">
                <h3 className="Typo--h3 snip-h3">Children</h3>
                <button
                  className="Cards--content-item-desktop-btn   "
                  aria-label="See more"
                >
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_circled_arrow_white.svg"
                    alt=""
                    loading="lazy"
                    className="snip-img"
                  />
                </button>
              </div>
              <div className="Cards--content-item-text snip-div">
                <div className="Cards--content-item-flex snip-div">
                  <figure>
                    <figcaption className="Testimonial">
                      <span className="Testimonial--img Typo--body-small-bold">
                        CT
                      </span>
                      <strong className="Testimonial--name Typo--body-small-bold">
                        Carole T.
                      </strong>
                      <small className="Testimonial--source Typo--body-small">
                        May 8, 2019 — Facebook
                      </small>
                    </figcaption>
                    <blockquote className="Typo--body-large">
                      For my son who does not leave it behind [...] it's like a
                      cuddly toy for him.
                    </blockquote>
                  </figure>
                </div>
                <button className="Cards--content-item-more-btn 0 Button Typo--button flex-row   ">
                  See more
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_angled_caret_right_white.svg"
                    alt=""
                    loading="lazy"
                    className="snip-img"
                  />
                </button>
              </div>
            </div>
          </div>
        </section>

          {/* model of the card         */}
        <section className="Cards--popups  Popup--overlay snip-section" ref={card_model}>
          <div className="Cards--popup hidden 1 Popup snip-div">
            <button className="Button--close-popup   " onClick={()=>card_toggle(0)}></button>
            <section className="Cards--popup-header card-1 snip-section">
              <h3 className="Typo--h3 snip-h3">Night awakenings</h3>
            </section>
            <section className="Cards--popup-body Popup--body snip-section">
              <figure className="Cards--popup-body-testimonial">
                <figcaption className="Testimonial">
                  <span className="Testimonial--img Typo--body-small-bold">FD</span>
                  <strong className="Testimonial--name Typo--body-small-bold">
                    Frederic D.
                  </strong>
                  <small className="Testimonial--source Typo--body-small">
                    May 2, 2019 — Amazon
                  </small>
                </figcaption>
                <blockquote className="Typo--body">
                  <p className="snip-p">
                    This device ... works ... or rather sends you to
                    sleep!&nbsp;
                    <span>
                      I've been using dodow for several weeks ..... and this is
                      the stuff of dreams!!&nbsp;
                    </span>
                    <span>
                      It makes me fall asleep really fast .... since I started
                      using it regularly. I did not even have the time to watch
                      the full 7 min session ... and I was already away with the
                      fairies. Suddenly, it is part of the trip during the next
                      holiday to the south ...
                    </span>
                  </p>
                  <p className="snip-p">
                    <span>
                      And if I wake up during the night, in a few minutes ... I
                      am back to sleep... whereas previously that took me more
                      than 1 hour.
                    </span>
                  </p>
                  <p className="snip-p">
                    Thanks to the designer of dodo. I now recommend it to my
                    loved ones.
                  </p>
                </blockquote>
              </figure>
              <div className="Cards--popup-body-wrap snip-div">
                <h4 className="Typo--h4 snip-h4">
                  <svg
                    className="Cards--popup-body-magnifier snip-svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.5643 14.4936C14.619 14.4936 17.0953 12.0323 17.0953 8.99614C17.0953 5.95995 14.619 3.49864 11.5643 3.49864C8.50955 3.49864 6.03322 5.95995 6.03322 8.99614C6.03322 12.0323 8.50955 14.4936 11.5643 14.4936ZM11.5643 17.4923C16.2852 17.4923 20.1122 13.6884 20.1122 8.99614C20.1122 4.30385 16.2852 0.5 11.5643 0.5C6.84335 0.5 3.01629 4.30385 3.01629 8.99614C3.01629 13.6884 6.84335 17.4923 11.5643 17.4923Z"
                      fill="#006666"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.112228 18.3796L6.5121 12.0186L8.6454 14.1389L2.24552 20.5L0.112228 18.3796Z"
                      fill="#006666"
                    ></path>
                  </svg>
                  The problem explained
                </h4>
                <div className="Typo--body snip-div">
                  <p className="snip-p">
                    In fact, it is quite normal to wake up in the middle of the
                    night.&nbsp;
                  </p>
                  <span></span>
                  <p className="snip-p">
                    <span>
                      Sleep is made up of cycles, and at the end of the cycle,
                      the slightest noise or unconscious stress can wake you
                      up.&nbsp;
                    </span>
                  </p>
                  <span></span>
                  <p className="snip-p">
                    <span>
                      The real problem is when you have a hard time falling back
                      asleep quickly.&nbsp;
                    </span>
                  </p>
                  <span></span>
                  <p className="snip-p">
                    <span>
                      In the vast majority of cases, this is due to anxiety and
                      stress (worries, a difficult day ahead, fear of not having
                      time to go back to sleep, fear of not hearing your alarm
                      clock ringing).&nbsp;
                    </span>
                  </p>
                  <p className="snip-p">
                    Yet, fear and anxiety activate a state of alertness. This is
                    a physiological mechanism characterized by the
                    overactivation of the autonomic nervous system.
                    Neurotransmitters are released, which helps stimulate the
                    arousal center and keep you awake.
                  </p>
                </div>
                <h4 className="Typo--h4 snip-h4">
                  <svg
                    className="Cards--popup-body-dodow snip-svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="10"
                      cy="10.5"
                      r="8.5"
                      stroke="#006666"
                      strokeWidth="3"
                    ></circle>
                    <circle cx="10" cy="6.5" r="1.5" fill="#006666"></circle>
                  </svg>
                  How Dodow helps you
                </h4>
                <div className="Typo--body snip-div">
                  <p className="snip-p">
                    Dodow can't stop you from waking up during the night, but it
                    can help you fall back to sleep really fast!&nbsp;
                  </p>
                  <p className="snip-p">
                    <span>
                      <span></span>
                      By breathing long enough in Dodow's rhythm (6 breaths per
                      minute), you will quickly go from the state of alertness
                      (activation of the sympathetic nervous system) to the
                      resting state (activation of the parasympathetic nervous
                      system), the same state in which you find yourself in
                      during digestion: slightly sleepy.&nbsp;
                    </span>
                  </p>
                  <p className="snip-p">
                    <span>
                      Synchronizing your breathing with Dodow has a hypnotic
                      effect (the phenomenon is similar to looking at a
                      pendulum). So, after a few minutes, you can let go and
                      fall asleep.&nbsp;
                    </span>
                  </p>
                  <p className="snip-p">
                    <span>
                      After a few months, you will regain confidence in
                      yourself, you will realize that your body is very good at
                      falling asleep on its own when the mind or anxiety are not
                      intervening. Then you can manage without Dodow!
                    </span>
                  </p>
                </div>
              </div>
              <a href="#bundle" className="Cards--popup-buy-btn snip-a">
                <button className="Button Button--default Button--icon Typo--button   " onClick={()=>card_toggle(0)}>
                  Buy Dodow
                </button>
              </a>
            </section>
          </div>
          <div className="Cards--popup hidden 2 Popup snip-div">
            <button className="Button--close-popup   " onClick={()=>card_toggle(1)}></button>
            <section className="Cards--popup-header card-2 snip-section">
              <h3 className="Typo--h3 snip-h3">Stress</h3>
            </section>
            <section className="Cards--popup-body Popup--body snip-section">
              <figure className="Cards--popup-body-testimonial">
                <figcaption className="Testimonial">
                  <span className="Testimonial--img Typo--body-small-bold">MG</span>
                  <strong className="Testimonial--name Typo--body-small-bold">
                    Mor Gane
                  </strong>
                  <small className="Testimonial--source Typo--body-small">
                    February 26, 2019 — Facebook
                  </small>
                </figcaption>
                <blockquote className="Typo--body">
                  <p className="snip-p">
                    I bought it over 2 years ago, I used it every night at the
                    beginning, and now I do only when I feel nervous, and at
                    times when I wake up during the night, I just think again at
                    the light and focus on the breathing exercises, and I fall
                    asleep easily.
                  </p>
                  <p className="snip-p">
                    Having been a paramedic in the past, I had a lot of trouble
                    falling asleep, because it made me very anxious.
                  </p>
                  <p className="snip-p">
                    Dodow helped me a lot in less than 15 days, I haven't used
                    it for a while because now I am falling asleep easily.
                  </p>
                  <p className="snip-p">
                    I had heard a lot about it in the media, but I was skeptical
                    about the price. However, going to Darty, I bought it on a
                    whim and I was persuaded, because it did a great job!
                  </p>
                </blockquote>
              </figure>
              <div className="Cards--popup-body-wrap snip-div">
                <h4 className="Typo--h4 snip-h4">
                  <svg
                    className="Cards--popup-body-magnifier snip-svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.5643 14.4936C14.619 14.4936 17.0953 12.0323 17.0953 8.99614C17.0953 5.95995 14.619 3.49864 11.5643 3.49864C8.50955 3.49864 6.03322 5.95995 6.03322 8.99614C6.03322 12.0323 8.50955 14.4936 11.5643 14.4936ZM11.5643 17.4923C16.2852 17.4923 20.1122 13.6884 20.1122 8.99614C20.1122 4.30385 16.2852 0.5 11.5643 0.5C6.84335 0.5 3.01629 4.30385 3.01629 8.99614C3.01629 13.6884 6.84335 17.4923 11.5643 17.4923Z"
                      fill="#006666"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.112228 18.3796L6.5121 12.0186L8.6454 14.1389L2.24552 20.5L0.112228 18.3796Z"
                      fill="#006666"
                    ></path>
                  </svg>
                  The problem explained
                </h4>
                <div className="Typo--body snip-div">
                  <p className="snip-p">
                    You are tense and relatively irritable. In such cases, a
                    defense mechanism called the state of alertness is at work.
                    This is characterized by the over-activation of the
                    autonomic nervous system. Your body is ready to face danger.
                    Neurotransmitters are secreted, which stimulate the arousal
                    center and keep you awake. In prehistoric times, this
                    physiological mechanism would have prevented you from
                    falling asleep in a hostile environment or when being
                    attacked by a bear, but today, when the danger is not real,
                    it prevents you from sleeping. Normally, the transition from
                    alertness to resting state is done automatically, but the
                    build-up of stress slows the process down, thus it will take
                    a few hours, instead of a few minutes.
                  </p>
                </div>
                <h4 className="Typo--h4 snip-h4">
                  <svg
                    className="Cards--popup-body-dodow snip-svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="10"
                      cy="10.5"
                      r="8.5"
                      stroke="#006666"
                      strokeWidth="3"
                    ></circle>
                    <circle cx="10" cy="6.5" r="1.5" fill="#006666"></circle>
                  </svg>
                  How Dodow helps you
                </h4>
                <div className="Typo--body snip-div">
                  <p className="snip-p">
                    By breathing long enough in Dodow's rhythm (6 breaths per
                    minute), you will quickly go from the state of alertness
                    (activation of the sympathetic nervous system) to the
                    resting state (activation of the parasympathetic nervous
                    system), the same state in which you find yourself in during
                    digestion: slightly sleepy.
                  </p>
                  <p className="snip-p">
                    In this state, you will be much less sensitive to your
                    stress, your metabolism will be slowed down, the secretion
                    of neurotransmitters that kept you awake will have been
                    stopped, and you will be ready to enter the Land of
                    Nod.&nbsp;
                  </p>
                  <p className="snip-p">
                    Synchronizing your breathing on a light which pulses at a
                    slow and regular rhythm has a hypnotic effect (the
                    phenomenon is similar to that of looking at a pendulum).
                    Thus, after a few minutes, you can let go and fall
                    asleep.&nbsp;
                  </p>
                  <p className="snip-p">
                    Effective, for example, on Sunday evening, when Monday
                    anxiety is preventing you from falling asleep!
                  </p>
                </div>
              </div>
              <a href="#bundle" className="Cards--popup-buy-btn snip-a">
                <button className="Button Button--default Button--icon Typo--button   " onClick={()=>card_toggle(1)}>
                  Buy Dodow
                </button>
              </a>
            </section>
          </div>
          <div className="Cards--popup hidden 3 Popup snip-div">
            <button className="Button--close-popup   " onClick={()=>card_toggle(2)}></button>
            <section className="Cards--popup-header card-3 snip-section">
              <h3 className="Typo--h3 snip-h3">
                Thoughts running through your head
              </h3>
            </section>
            <section className="Cards--popup-body Popup--body snip-section">
              <figure className="Cards--popup-body-testimonial">
                <figcaption className="Testimonial">
                  <span className="Testimonial--img Typo--body-small-bold">LC</span>
                  <strong className="Testimonial--name Typo--body-small-bold">
                    Laure C.
                  </strong>
                  <small className="Testimonial--source Typo--body-small">
                    June 5, 2018 — Facebook
                  </small>
                </figcaption>
                <blockquote className="Typo--body">
                  <p className="snip-p">
                    Excellent product! I find that the pace at the start is
                    always a little too fast for me, but then it gradually
                    decreases, so I can keep up without difficulty. I follow the
                    advice on the instructions and on the site, so I can fall
                    asleep much more easily. Of course, some nights my brain is
                    more active than others, so falling asleep takes a little
                    longer, but Dodow helps reduce that time enormously!
                  </p>
                </blockquote>
              </figure>
              <div className="Cards--popup-body-wrap snip-div">
                <h4 className="Typo--h4 snip-h4">
                  <svg
                    className="Cards--popup-body-magnifier snip-svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.5643 14.4936C14.619 14.4936 17.0953 12.0323 17.0953 8.99614C17.0953 5.95995 14.619 3.49864 11.5643 3.49864C8.50955 3.49864 6.03322 5.95995 6.03322 8.99614C6.03322 12.0323 8.50955 14.4936 11.5643 14.4936ZM11.5643 17.4923C16.2852 17.4923 20.1122 13.6884 20.1122 8.99614C20.1122 4.30385 16.2852 0.5 11.5643 0.5C6.84335 0.5 3.01629 4.30385 3.01629 8.99614C3.01629 13.6884 6.84335 17.4923 11.5643 17.4923Z"
                      fill="#006666"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.112228 18.3796L6.5121 12.0186L8.6454 14.1389L2.24552 20.5L0.112228 18.3796Z"
                      fill="#006666"
                    ></path>
                  </svg>
                  The problem explained
                </h4>
                <div className="Typo--body snip-div">
                  <p className="snip-p">
                    In the vast majority of cases, anxiety and stress keep you
                    from going back to sleep.&nbsp;
                  </p>
                  <p className="snip-p">
                    When you pay attention to your thoughts and they excite or
                    frighten you, you release neurotransmitters, which can
                    signal the brain to stay awake. It's like watching a
                    fascinating movie: it's hard to fall asleep during it.&nbsp;
                  </p>
                  <p className="snip-p">
                    Every now and then your thoughts just cross your mind, but
                    sometimes they feel almost real to you. When you go to bed,
                    you are still too vigilant and alert, so the slightest
                    thought keeps you awake.
                  </p>
                </div>
                <h4 className="Typo--h4 snip-h4">
                  <svg
                    className="Cards--popup-body-dodow snip-svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="10"
                      cy="10.5"
                      r="8.5"
                      stroke="#006666"
                      strokeWidth="3"
                    ></circle>
                    <circle cx="10" cy="6.5" r="1.5" fill="#006666"></circle>
                  </svg>
                  How Dodow helps you
                </h4>
                <div className="Typo--body snip-div">
                  <p className="snip-p">
                    Dodow is not a miracle product, but a clever tool: by
                    focusing on the light signal and synchronizing your
                    breathing with its rhythm, Dodow allows you to create a
                    diversion and occupy your brain with an activity that is
                    much less exciting than your thoughts.&nbsp;
                  </p>
                  <p className="snip-p">
                    Thus, you will quickly go from the state of alertness
                    (activation of the sympathetic nervous system) to the
                    resting state (activation of the parasympathetic nervous
                    system). In this state, you will be much less sensitive to
                    stimuli, whether they are external, like noise, light, or
                    internal, such as thoughts. You can then more easily let
                    your thoughts slip away, without paying the slightest
                    attention to them.&nbsp;
                  </p>
                  <p className="snip-p">
                    Fun fact: in Russia, a scientist named Pavlov found that by
                    placing dogs in empty rooms, where nothing could stimulate
                    their curiosity, they fell asleep right away. Dodow produces
                    the same effect in your head!
                  </p>
                </div>
              </div>
              <a href="#bundle" className="Cards--popup-buy-btn snip-a">
                <button className="Button Button--default Button--icon Typo--button   " onClick={()=>card_toggle(2)}>
                  Buy Dodow
                </button>
              </a>
            </section>
          </div>
          <div className="Cards--popup hidden 4 Popup snip-div">
            <button className="Button--close-popup   " onClick={()=>card_toggle(3)}></button>
            <section className="Cards--popup-header card-4 snip-section">
              <h3 className="Typo--h3 snip-h3">Chronic insomnia</h3>
            </section>
            <section className="Cards--popup-body Popup--body snip-section">
              <figure className="Cards--popup-body-testimonial">
                <figcaption className="Testimonial">
                  <span className="Testimonial--img Typo--body-small-bold">LC</span>
                  <strong className="Testimonial--name Typo--body-small-bold">
                    Lauranne C.
                  </strong>
                  <small className="Testimonial--source Typo--body-small">
                    May 28, 2018 — Facebook
                  </small>
                </figcaption>
                <blockquote className="Typo--body">
                  <p className="snip-p">
                    For as long as I can remember I have suffered from insomnia.
                    I tried all methods and took sleeping pills, with no real
                    results. So, despite not being totally convinced, I bought a
                    Dodow, being a bit desperate. It took me a few nights to
                    adjust and properly align my breathing with the light. But,
                    after 2/3 days, I adapted perfectly, and I was surprised by
                    the results: shortly after the end of the 20 min program, I
                    fell asleep like a log, without even realizing it! Since
                    then, I keep it with me all the time! I use it to sleep, but
                    sometimes also to relax during the day (especially to help
                    me concentrate, just before an important task). I love it
                    and recommend it to everyone I meet ?
                  </p>
                </blockquote>
              </figure>
              <div className="Cards--popup-body-wrap snip-div">
                <h4 className="Typo--h4 snip-h4">
                  <svg
                    className="Cards--popup-body-magnifier snip-svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.5643 14.4936C14.619 14.4936 17.0953 12.0323 17.0953 8.99614C17.0953 5.95995 14.619 3.49864 11.5643 3.49864C8.50955 3.49864 6.03322 5.95995 6.03322 8.99614C6.03322 12.0323 8.50955 14.4936 11.5643 14.4936ZM11.5643 17.4923C16.2852 17.4923 20.1122 13.6884 20.1122 8.99614C20.1122 4.30385 16.2852 0.5 11.5643 0.5C6.84335 0.5 3.01629 4.30385 3.01629 8.99614C3.01629 13.6884 6.84335 17.4923 11.5643 17.4923Z"
                      fill="#006666"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.112228 18.3796L6.5121 12.0186L8.6454 14.1389L2.24552 20.5L0.112228 18.3796Z"
                      fill="#006666"
                    ></path>
                  </svg>
                  The problem explained
                </h4>
                <div className="Typo--body snip-div">
                  <p className="snip-p">
                    “Will I be able to fall asleep tonight?”. You can already
                    imagine your exhaustion the next day. This fear of not
                    sleeping is self-fulfilling and can lead to recurrent
                    insomnia. Your body hasn't actually forgotten how to fall
                    asleep, but just believing that you won't be able to do it
                    tonight influences you and keeps you from falling
                    asleep.&nbsp;
                  </p>
                  <p className="snip-p">
                    Fear is a psychological phenomenon, but also a physiological
                    one: neurotransmitters are released and they keep you awake.
                    In prehistoric times, this mechanism would have prevented
                    you from falling asleep in a hostile environment and when
                    being attacked by a bear, but today, when the danger is not
                    real, it prevents you from sleeping.&nbsp;
                  </p>
                  <p className="snip-p">
                    You become aware of this anxiety, you still do not sleep,
                    you stress even more ... It is a real vicious circle!
                  </p>
                </div>
                <h4 className="Typo--h4 snip-h4">
                  <svg
                    className="Cards--popup-body-dodow snip-svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="10"
                      cy="10.5"
                      r="8.5"
                      stroke="#006666"
                      strokeWidth="3"
                    ></circle>
                    <circle cx="10" cy="6.5" r="1.5" fill="#006666"></circle>
                  </svg>
                  How Dodow helps you
                </h4>
                <div className="Typo--body snip-div">
                  <p className="snip-p">
                    When following the routine for 20 minutes, your only goal
                    will be to breathe in rhythm with the light. Thus, you will
                    free yourself from your fear of not being able to fall
                    asleep.
                  </p>
                  <p className="snip-p">
                    By breathing long enough in Dodow's rhythm (6 breaths per
                    minute), you will quickly go from the state of alertness
                    (activation of the sympathetic nervous system) to the
                    resting state (activation of the parasympathetic nervous
                    system), the same state in which you find yourself in during
                    digestion: slightly sleepy.
                  </p>
                  <p className="snip-p">
                    Synchronizing your breathing on a light which pulses at a
                    slow and regular rhythm has a hypnotic effect (the
                    phenomenon is similar to that of looking at a pendulum).
                    Thus, after a few minutes, you can let go and fall
                    asleep.&nbsp;
                  </p>
                  <p className="snip-p">
                    You will regain confidence in yourself and understand that
                    your body still knows how to fall asleep.
                  </p>
                </div>
              </div>
              <a href="#bundle" className="Cards--popup-buy-btn snip-a">
                <button className="Button Button--default Button--icon Typo--button   " onClick={()=>card_toggle(3)}>
                  Buy Dodow
                </button>
              </a>
            </section>
          </div>
          <div className="Cards--popup hidden 5 Popup snip-div">
            <button className="Button--close-popup   " onClick={()=>card_toggle(4)}></button>
            <section className="Cards--popup-header card-5 snip-section">
              <h3 className="Typo--h3 snip-h3">Worries</h3>
            </section>
            <section className="Cards--popup-body Popup--body snip-section">
              <figure className="Cards--popup-body-testimonial">
                <figcaption className="Testimonial">
                  <span className="Testimonial--img Typo--body-small-bold">CP</span>
                  <strong className="Testimonial--name Typo--body-small-bold">
                    Cindy P.
                  </strong>
                  <small className="Testimonial--source Typo--body-small">
                    January 19, 2019 — Facebook
                  </small>
                </figcaption>
                <blockquote className="Typo--body">
                  <p className="snip-p">
                    First night with Dodow. I've been taking sleeping pills for
                    10 years (+ all sorts of funny things for sleeping, which I
                    quit 6 months ago), and now, at night, I need no sleeping
                    pills. I said to myself I should test Dodow to check whether
                    the thing works. I had it set for the 20-minute mode, but I
                    think the 8 minutes one would have been enough. Although I
                    tried to resist, I couldn't have lasted more than 5-6
                    minutes. I woke up during the night, went back to Dodow and
                    2-3 minutes later I was again in the arms of Morpheus.
                  </p>
                  <p className="snip-p">
                    I hope it will carry on like this !! But, in any case, thank
                    you Dodow, because, thanks to you, I can stop the sleeping
                    pills, so I will be eternally grateful to you 🤗🤗🤗
                  </p>
                </blockquote>
              </figure>
              <div className="Cards--popup-body-wrap snip-div">
                <h4 className="Typo--h4 snip-h4">
                  <svg
                    className="Cards--popup-body-magnifier snip-svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.5643 14.4936C14.619 14.4936 17.0953 12.0323 17.0953 8.99614C17.0953 5.95995 14.619 3.49864 11.5643 3.49864C8.50955 3.49864 6.03322 5.95995 6.03322 8.99614C6.03322 12.0323 8.50955 14.4936 11.5643 14.4936ZM11.5643 17.4923C16.2852 17.4923 20.1122 13.6884 20.1122 8.99614C20.1122 4.30385 16.2852 0.5 11.5643 0.5C6.84335 0.5 3.01629 4.30385 3.01629 8.99614C3.01629 13.6884 6.84335 17.4923 11.5643 17.4923Z"
                      fill="#006666"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.112228 18.3796L6.5121 12.0186L8.6454 14.1389L2.24552 20.5L0.112228 18.3796Z"
                      fill="#006666"
                    ></path>
                  </svg>
                  The problem explained
                </h4>
                <div className="Typo--body snip-div">
                  <p className="snip-p">
                    A difficult break-up, loss of a job or temporary depression
                    are often accompanied by insomnia. Act quickly, before
                    insomnia sets in and continues to haunt you even after your
                    worries are behind you. In the vast majority of cases,
                    anxiety and stress keep you from going back to sleep.&nbsp;
                  </p>
                  <p className="snip-p">
                    Stress often comes with big worries and it is the root cause
                    of your sleeping problems. Indeed, stress has physiological
                    consequences, which result in an imbalance of the autonomic
                    nervous system. Your body is ready to face a dangerous
                    situation, you are on alert, which is characterized in
                    particular by the release of neurotransmitters that keep you
                    awake, by stimulating your arousal center.
                  </p>
                </div>
                <h4 className="Typo--h4 snip-h4">
                  <svg
                    className="Cards--popup-body-dodow snip-svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="10"
                      cy="10.5"
                      r="8.5"
                      stroke="#006666"
                      strokeWidth="3"
                    ></circle>
                    <circle cx="10" cy="6.5" r="1.5" fill="#006666"></circle>
                  </svg>
                  How Dodow helps you
                </h4>
                <div className="Typo--body snip-div">
                  <p className="snip-p">
                    By focusing on the light signal and synchronizing your
                    breathing with its rhythm, you will be occupying much of
                    your attention with an activity more relaxing than thinking
                    about your problems.
                  </p>
                  <p className="snip-p">
                    On the other hand, the regular pulsation of light has a
                    hypnotic effect. Thus, after a few minutes, you can let go
                    and fall asleep.&nbsp;
                  </p>
                  <p className="snip-p">
                    By breathing long enough in Dodow's rhythm (6 breaths per
                    minute), you will quickly go from the state of alertness
                    (activation of the sympathetic nervous system) to the
                    resting state (activation of the parasympathetic nervous
                    system), the same state in which you find yourself in during
                    digestion: slightly sleepy.
                  </p>
                  <p className="snip-p">
                    Situations: the day before an important event such as a job
                    interview, end-of-year exams or your wedding.
                  </p>
                </div>
              </div>
              <a href="#bundle" className="Cards--popup-buy-btn snip-a">
                <button className="Button Button--default Button--icon Typo--button   " onClick={()=>card_toggle(4)}>
                  Buy Dodow
                </button>
              </a>
            </section>
          </div>
          <div className="Cards--popup hidden 6 Popup snip-div">
            <button className="Button--close-popup   " onClick={()=>card_toggle(5)}></button>
            <section className="Cards--popup-header card-6 snip-section">
              <h3 className="Typo--h3 snip-h3">Children</h3>
            </section>
            <section className="Cards--popup-body Popup--body snip-section">
              <figure className="Cards--popup-body-testimonial">
                <figcaption className="Testimonial">
                  <span className="Testimonial--img Typo--body-small-bold">CT</span>
                  <strong className="Testimonial--name Typo--body-small-bold">
                    Carole T.
                  </strong>
                  <small className="Testimonial--source Typo--body-small">
                    May 8, 2019 — Facebook
                  </small>
                </figcaption>
                <blockquote className="Typo--body">
                  <p className="snip-p">
                    The little box you need for a lifetime. my daughter and I
                    really appreciate it! it's truly great to have regained a
                    restful sleep.
                  </p>
                  <p className="snip-p">
                    My sleep was very disturbed because I have sleep apnea and
                    asthma. The Dodow allowed me to breathe better and allowed
                    me to sleep better. My 12 and 5-year-old used it too. It
                    helped me reduce my anxiety and hyperventilation
                  </p>
                  <p className="snip-p">
                    Focusing on the blue light allowed me to stop concentrating
                    on my breathing problems. I don't use it anymore, but it
                    works really well, it really helped me fall asleep, which is
                    especially complicated with asthma, but Dodow was able to
                    help me.&nbsp;
                  </p>
                  <p className="snip-p">
                    It's the same for my son, who does not leave it behind even
                    when he goes to sleep at his grandparents' (he does not lose
                    sight of it, it's like a cuddly toy for him)
                  </p>
                  <p className="snip-p">I am grateful to Dodow for helping us</p>
                </blockquote>
              </figure>
              <div className="Cards--popup-body-wrap snip-div">
                <h4 className="Typo--h4 snip-h4">
                  <svg
                    className="Cards--popup-body-magnifier snip-svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.5643 14.4936C14.619 14.4936 17.0953 12.0323 17.0953 8.99614C17.0953 5.95995 14.619 3.49864 11.5643 3.49864C8.50955 3.49864 6.03322 5.95995 6.03322 8.99614C6.03322 12.0323 8.50955 14.4936 11.5643 14.4936ZM11.5643 17.4923C16.2852 17.4923 20.1122 13.6884 20.1122 8.99614C20.1122 4.30385 16.2852 0.5 11.5643 0.5C6.84335 0.5 3.01629 4.30385 3.01629 8.99614C3.01629 13.6884 6.84335 17.4923 11.5643 17.4923Z"
                      fill="#006666"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.112228 18.3796L6.5121 12.0186L8.6454 14.1389L2.24552 20.5L0.112228 18.3796Z"
                      fill="#006666"
                    ></path>
                  </svg>
                  The problem explained
                </h4>
                <div className="Typo--body snip-div">
                  <p className="snip-p">
                    Some children are very anxious at bedtime. They can have all
                    kinds of fears, ranging from fear of the dark to those of
                    monsters hiding under the bed. They may also fear being
                    alone or having nightmares.
                  </p>
                  <p className="snip-p">
                    Fear is a psychological phenomenon, but it is also
                    physiological: neurotransmitters are released, and they keep
                    your child awake.
                  </p>
                  <p className="snip-p">
                    A vicious circle then sets in: your child is afraid, so they
                    do not fall asleep, then the stress of not falling asleep is
                    added to the already existing stress ...
                  </p>
                </div>
                <h4 className="Typo--h4 snip-h4">
                  <svg
                    className="Cards--popup-body-dodow snip-svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="10"
                      cy="10.5"
                      r="8.5"
                      stroke="#006666"
                      strokeWidth="3"
                    ></circle>
                    <circle cx="10" cy="6.5" r="1.5" fill="#006666"></circle>
                  </svg>
                  How Dodow helps you
                </h4>
                <div className="Typo--body snip-div">
                  <p className="snip-p">
                    By focusing on the light signal and synchronizing their
                    breathing with its rhythm, your child will focus on
                    something other than fears, just as they do when you tell
                    them a story.
                  </p>
                  <p className="snip-p">
                    On the other hand, the regular pulsation of light has a
                    hypnotic effect. Thus, after a few minutes, they will be
                    relaxed, able to let go, and fall asleep.&nbsp;
                  </p>
                  <p className="snip-p">
                    By breathing long enough in Dodow's rhythm (6 breaths per
                    minute), your child will quickly go from the state of
                    alertness (activation of the sympathetic nervous system) to
                    the resting state (activation of the parasympathetic nervous
                    system), the same state in which we are in during digestion:
                    slightly sleepy.
                  </p>
                  <p className="snip-p">
                    Bonus: Dodow's blue light will soothe your child, and
                    reassure them, just like a nightlight!
                  </p>
                </div>
              </div>
              <a href="#bundle" className="Cards--popup-buy-btn snip-a">
                <button className="Button Button--default Button--icon Typo--button   " onClick={()=>card_toggle(5)}>
                  Buy Dodow
                </button>
              </a>
            </section>
          </div>
        </section>
      </div>
    </div>
  );
}

export default FourthSection;
