import React from "react";
import "./comments.css";

export default function Comments() {
  return (
    <div>
      <div className="Wrap--light snipcss-Ol7Yo snip-div" id="reviews">
        <div className="Container Reviews snip-div">
          <header>
            <h2 className="Typo--h2 snip-h2">Saved from insomnia</h2>
          </header>
          <div className="Reviews--wrap cards-column Typo--body snip-div">
            <figure className="Reviews--review card">
              <figcaption className="Testimonial">
                <span className="Testimonial--img Testimonial--img-small Typo--body-smaller-bold">
                  TS
                </span>
                <strong className="Testimonial--name Typo--body-smaller-bold">
                  Terri S
                </strong>
                <small className="Testimonial--source Typo--body-smaller">
                  March 24, 2021
                </small>
                <div className="Testimonial--rating Reviews--review-note snip-div">
                  <ul className="snip-ul">
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                  </ul>
                </div>
              </figcaption>
              <blockquote className="Typo--body-small">
                <strong>This product is AWESOME!</strong>I am (or was) a
                notoriously bad sleeper - having used this for a couple of
                weeks, I've retrained my body to be able to go to sleep quicker
                and stay asleep. On the occasions where I wake up during the
                night I find it easier to fall back asleep - but if I have
                difficultyI use my DODOW again for a few and voila!
                <strong>
                  I'm sleeping again! I recommend it for EVERYONE who has sleep
                  issues!
                </strong>
              </blockquote>
            </figure>
            <figure className="Reviews--review card">
              <figcaption className="Testimonial">
                <span className="Testimonial--img Testimonial--img-small Typo--body-smaller-bold">
                  WJ
                </span>
                <strong className="Testimonial--name Typo--body-smaller-bold">
                  Wiles judy
                </strong>
                <small className="Testimonial--source Typo--body-smaller">
                  December 18, 2019
                </small>
                <div className="Testimonial--rating Reviews--review-note snip-div">
                  <ul className="snip-ul">
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                  </ul>
                </div>
              </figcaption>
              <blockquote className="Typo--body-small">
                It is the greatest product.
                <strong>I no longer need sleeping pills.</strong>
              </blockquote>
            </figure>
            <figure className="Reviews--review card">
              <figcaption className="Testimonial">
                <span className="Testimonial--img Testimonial--img-small Typo--body-smaller-bold">
                  FL
                </span>
                <strong className="Testimonial--name Typo--body-smaller-bold">
                  Flannery, Lori
                </strong>
                <small className="Testimonial--source Typo--body-smaller">
                  August 15, 2019
                </small>
                <div className="Testimonial--rating Reviews--review-note snip-div">
                  <ul className="snip-ul">
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                  </ul>
                </div>
              </figcaption>
              <blockquote className="Typo--body-small">
                I was 99% certain there was no way this product was going to
                work but the 1% chance that it MIGHT won.
                <strong>
                  I still don't understand how in the heck it works but it does.
                </strong>
                The weirdest thing is, now I can even fall asleep WITHOUT it
                most nights!!!
              </blockquote>
            </figure>
            <figure className="Reviews--review card">
              <figcaption className="Testimonial">
                <span className="Testimonial--img Testimonial--img-small Typo--body-smaller-bold">
                  DA
                </span>
                <strong className="Testimonial--name Typo--body-smaller-bold">
                  Davison
                </strong>
                <small className="Testimonial--source Typo--body-smaller">
                  February 17, 2019
                </small>
                <div className="Testimonial--rating Reviews--review-note snip-div">
                  <ul className="snip-ul">
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                  </ul>
                </div>
              </figcaption>
              <blockquote className="Typo--body-small">
                I love it! I never knew when I woul fall asleep. some times it
                took all night. I was sceptical about this product but not
                anymore.
                <strong>
                  It works fabulously if you follow all the directions
                </strong>
                I cannot thank you enough!
              </blockquote>
            </figure>
            <figure className="Reviews--review card">
              <figcaption className="Testimonial">
                <span className="Testimonial--img Testimonial--img-small Typo--body-smaller-bold">
                  JO
                </span>
                <strong className="Testimonial--name Typo--body-smaller-bold">
                  John
                </strong>
                <small className="Testimonial--source Typo--body-smaller">
                  June 2, 2021
                </small>
                <div className="Testimonial--rating Reviews--review-note snip-div">
                  <ul className="snip-ul">
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                  </ul>
                </div>
              </figcaption>
              <blockquote className="Typo--body-small">
                <strong>Love my dodow</strong>
              </blockquote>
            </figure>
            <figure className="Reviews--review card">
              <figcaption className="Testimonial">
                <span className="Testimonial--img Testimonial--img-small Typo--body-smaller-bold">
                  MO
                </span>
                <strong className="Testimonial--name Typo--body-smaller-bold">
                  Moumou
                </strong>
                <small className="Testimonial--source Typo--body-smaller">
                  April 26, 2021
                </small>
                <div className="Testimonial--rating Reviews--review-note snip-div">
                  <ul className="snip-ul">
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                  </ul>
                </div>
              </figcaption>
              <blockquote className="Typo--body-small">
                Love my dodow!
                <strong>
                  I'm now getting better sleep. It helps a lot with anxious
                  thoughts at night
                </strong>
              </blockquote>
            </figure>
            <figure className="Reviews--review card">
              <figcaption className="Testimonial">
                <span className="Testimonial--img Testimonial--img-small Typo--body-smaller-bold">
                  TS
                </span>
                <strong className="Testimonial--name Typo--body-smaller-bold">
                  Terry Simon
                </strong>
                <small className="Testimonial--source Typo--body-smaller">
                  March 23, 2021
                </small>
                <div className="Testimonial--rating Reviews--review-note snip-div">
                  <ul className="snip-ul">
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                  </ul>
                </div>
              </figcaption>
              <blockquote className="Typo--body-small">love my dodow</blockquote>
            </figure>
            <figure className="Reviews--review card">
              <figcaption className="Testimonial">
                <span className="Testimonial--img Testimonial--img-small Typo--body-smaller-bold">
                  MC
                </span>
                <strong className="Testimonial--name Typo--body-smaller-bold">
                  Mcgurk
                </strong>
                <small className="Testimonial--source Typo--body-smaller">
                  July 4, 2019
                </small>
                <div className="Testimonial--rating Reviews--review-note snip-div">
                  <ul className="snip-ul">
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                  </ul>
                </div>
              </figcaption>
              <blockquote className="Typo--body-small">
                I LOVE THIS !! I bought 2 one for myself and one for my 15 year
                old Granddaughter. I can not even express
                <strong>
                  how much I appreciate this non medicinal sleep assistance !
                </strong>
                THANK YOU, THANK YOU, THANK YOU ???
              </blockquote>
            </figure>
            <figure className="Reviews--review card">
              <figcaption className="Testimonial">
                <span className="Testimonial--img Testimonial--img-small Typo--body-smaller-bold">
                  JI
                </span>
                <strong className="Testimonial--name Typo--body-smaller-bold">
                  John R. Short II
                </strong>
                <small className="Testimonial--source Typo--body-smaller">
                  March 30, 2019
                </small>
                <div className="Testimonial--rating Reviews--review-note snip-div">
                  <ul className="snip-ul">
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star checked"></i>
                    </li>
                    <li className="snip-li">
                      <i className="total-star"></i>
                    </li>
                  </ul>
                </div>
              </figcaption>
              <blockquote className="Typo--body-small">
                works well and
                <strong>we enjoy a more restful nights sleep</strong>
              </blockquote>
            </figure>
          </div>
          <div className="Reviews--bottom snip-div">
            <a
              type="button"
              id="more-reviews"
              className="Typo--button Button Button--green Button--caret "
              href="https://www.mydodow.com/dodow/en-us/comments"
            
            >
           See more
            </a>
            <div className="Reviews--bottom-gradient snip-div"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
