import React, { useRef } from "react";
import "./questions.css";

function Questions() {
  const more_qus = useRef(null);

  let temp_id = null;

  const qus_open = (element, id) => {
    const doc = document.querySelectorAll(".Questions--paragraph");

    if (temp_id !== null && temp_id !== id) {
      doc[temp_id].classList.toggle("q-open");
    }

    doc[id].classList.toggle("q-open");
    temp_id = id;
  };

  return (
    <div>
      <div className="questions-wrap   Wrap--light  ">
        <section id="questions" className="Container Questions   ">
          <header>
            <h2 className="Typo--h2">Your questions</h2>
          </header>
          <div className="Questions--image">
            <img
              src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow_unboxing_573.png"
              srcSet="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow_unboxing_460.png 460w, https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow_unboxing_515.png 515w, https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow_unboxing_573.png 573w, https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow_unboxing_675.png 675w, https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow_unboxing_997.png 997w, "
              sizes=" min-width: 768px   150vw, 700px"
              alt="Dodow"
              loading="lazy"
            />
          </div>
          <div className="Questions--wrap">
            <div
              className="Questions--paragraph Flex Flex--column"
              onClick={(element) => {
                qus_open(element, 0);
              }}
            >
              <div className="Flex Flex--row ">
                <p className="Questions--paragraph-title Typo--h4">
                  Why do I need Dodow ?
                </p>
                <button>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 7L15.2929 12.2929C15.6834 12.6834 15.6834 13.3166 15.2929 13.7071L10 19"
                      stroke="#006666"
                      strokeWidth="3"
                    ></path>
                  </svg>
                </button>
              </div>
              <p className="Questions--paragraph-text Typo--body">
                Unless you have received special training, like Air Force
                fighter pilots, this cardiac coherence exercise is very
                difficult to do without a breathing guide! Giving Caesar his
                due, the breathing exercises of Sophrology are themselves
                inspired by Yoga Pranayama. Here are the 4 reasons why Dodow is
                not just a simple Sophrology breathing exercise:
                <br></br>
                1. Dodow indicates an optimal pace for you: 6 breaths per minute
                to put you in a state of rest and to rebalance your autonomic
                nervous system. Every breath out is 50% longer than the breath
                in to tire you out. Trying to follow your own breathing
                independently forces you to think and to count, which causes
                performance anxiety, thereby significantly delaying sleep.
                <br></br>
                2. The attention span of a person not used to meditating is
                thirty seconds at most, so you can very quickly become lost in
                your thoughts and forget to do the breathing exercise. Dodow's
                light signal keeps you on track!
                <br></br>
                3. Synchronizing one's breathing with a slow and steady light
                pulse has a hypnotic effect, similar to watching a pendulum that
                helps you unwind quickly
                <br></br>
                4. Dodow helps you simmer down and calm the flow of your
                thoughts by keeping your attention focused on an external point
                (the light signal), without your having to concentrate on your
                breathing - the emphasis is paradoxically placed on keeping you
                awake.
                <br></br>
                We have no doubt as to the effectiveness of simple breathing
                exercises, but are less confident about the ability of our users
                to do them on their own .
              </p>
            </div>

            <div
              className="Questions--paragraph Flex Flex--column"
              onClick={(element) => {
                qus_open(element, 1);
              }}
            >
              <div className="Flex Flex--row">
                <p className="Questions--paragraph-title Typo--h4">
                  Will Dodow disturb the person lying next to me?
                </p>
                <button>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 7L15.2929 12.2929C15.6834 12.6834 15.6834 13.3166 15.2929 13.7071L10 19"
                      stroke="#006666"
                      strokeWidth="3"
                    ></path>
                  </svg>
                </button>
              </div>
              <p className="Questions--paragraph-text Typo--body">
                No. If the person's eyes are closed, they will not be disturbed;
                if they do the exercise with you, they will fall asleep very
                quickly.
              </p>
            </div>

            <div
              className="Questions--paragraph Flex Flex--column"
              onClick={(element) => {
                qus_open(element, 2);
              }}
            >
              <div className="Flex Flex--row">
                <p className="Questions--paragraph-title Typo--h4">
                  Why blue light?
                </p>
                <button>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 7L15.2929 12.2929C15.6834 12.6834 15.6834 13.3166 15.2929 13.7071L10 19"
                      stroke="#006666"
                      strokeWidth="3"
                    ></path>
                  </svg>
                </button>
              </div>
              <p className="Questions--paragraph-text Typo--body">
                The color blue was chosen after much testing, based on the
                generally accepted perception that blue is calming. Blue light
                in high doses can help wake you up by inhibiting melatonin
                secretion, but Dodow does not. Being projected on the ceiling,
                the light signal, at its maximum intensity and for someone who
                is lying in bed, has a very low intensity less than 1 lux ,
                compared with the relatively strong intensity of a computer or
                television screen about 60 lux at a distance of 50cm . In
                addition, the exposure time between 8 and 20 minutes is low.
                Dodow's impact on the circadian rhythm is therefore almost zero
                or, in any case, far too low to delay falling asleep and
                counteract the positive effects described above. The luminous
                halo is, therefore, in fact, more similar to color than to
                light.
              </p>
            </div>

            <div
              className="Questions--paragraph Flex Flex--column"
              onClick={(element) => {
                qus_open(element, 3);
              }}
            >
              <div className="Flex Flex--row">
                <p className="Questions--paragraph-title Typo--h4">
                  Does Dodow also work for jet lag?
                </p>
                <button>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 7L15.2929 12.2929C15.6834 12.6834 15.6834 13.3166 15.2929 13.7071L10 19"
                      stroke="#006666"
                      strokeWidth="3"
                    ></path>
                  </svg>
                </button>
              </div>
              <p className="Questions--paragraph-text Typo--body">
                Dodow works great for jet lag or irregular sleep phases. Dodow
                is also used by Air France flight staff, with whom we have
                entered into a partnership. They have 50,000 cabin crew members
                traveling around the world.
              </p>
            </div>
            <div style={{ display: "none" }} ref={more_qus}>
              <div
                className="Questions--paragraph Flex Flex--column"
                onClick={(element) => {
                  qus_open(element, 4);
                }}
              >
                <div className="Flex Flex--row">
                  <p className="Questions--paragraph-title Typo--h4">
                    I am really stressed, can Dodow help me?
                  </p>
                  <button>
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 7L15.2929 12.2929C15.6834 12.6834 15.6834 13.3166 15.2929 13.7071L10 19"
                        stroke="#006666"
                        strokeWidth="3"
                      ></path>
                    </svg>
                  </button>
                </div>
                <p className="Questions--paragraph-text Typo--body">
                  I am really stressed, can Dodow help me?
                </p>
              </div>

              <div
                className="Questions--paragraph  Flex Flex--column"
                onClick={(element) => {
                  qus_open(element, 5);
                }}
              >
                <div className="Flex Flex--row">
                  <p className="Questions--paragraph-title Typo--h4">
                    Can Dodow help me fall back to sleep if I wake up in the
                    middle of the night?
                  </p>
                  <button>
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 7L15.2929 12.2929C15.6834 12.6834 15.6834 13.3166 15.2929 13.7071L10 19"
                        stroke="#006666"
                        strokeWidth="3"
                      ></path>
                    </svg>
                  </button>
                </div>
                <p className="Questions--paragraph-text Typo--body">
                  Yes, you just need to touch the surface of the Dodow to turn
                  it back on, just like you do when you want to fall asleep. The
                  routine is the same as at the beginning of the night.
                  Synchronizing your breathing with the luminous halo, which
                  pulses in a slow and regular rhythm, has a hypnotic effect the
                  phenomenon is similar to that of looking at a pendulum . So
                  after a few minutes, you will be able to unwind and fall
                  asleep.
                </p>
              </div>

              <div
                className="Questions--paragraph  Flex Flex--column"
                onClick={(element) => {
                  qus_open(element, 6);
                }}
              >
                <div className="Flex Flex--row">
                  <p className="Questions--paragraph-title Typo--h4">
                    Is Dodow suitable for children and the elderly?
                  </p>
                  <button>
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 7L15.2929 12.2929C15.6834 12.6834 15.6834 13.3166 15.2929 13.7071L10 19"
                        stroke="#006666"
                        strokeWidth="3"
                      ></path>
                    </svg>
                  </button>
                </div>
                <p className="Questions--paragraph-text Typo--body">
                  Yes, we have plenty of testimonials on this we encourage you
                  to browse through them. The breathing routine proposed by
                  Dodow is very simple and should be done gradually and calmly.
                  Dodow is particularly well suited for people who are afraid of
                  the dark numerous in children and the elderly. Dodow can be
                  used from the age of 6.
                </p>
              </div>

              <div
                className="Questions--paragraph  Flex Flex--column"
                onClick={(element) => {
                  qus_open(element, 7);
                }}
              >
                <div className="Flex Flex--row">
                  <p className="Questions--paragraph-title Typo--h4">
                    How quickly would Dodow allow me to fall asleep?
                  </p>
                  <button>
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 7L15.2929 12.2929C15.6834 12.6834 15.6834 13.3166 15.2929 13.7071L10 19"
                        stroke="#006666"
                        strokeWidth="3"
                      ></path>
                    </svg>
                  </button>
                </div>
                <p className="Questions--paragraph-text Typo--body">
                  Results may vary depending on individuals, but most people
                  will fall asleep easily within the first 20 minutes, while
                  many do fall asleep even before the end of the 8-minute cycle.
                  Our users tell us they fall asleep 2.5 times faster thanks to
                  Dodow. And, please remember, the more you use Dodow, the
                  better results you can expect - or it is even possible not to
                  need it any longer -.
                </p>
              </div>

              <div
                className="Questions--paragraph  Flex Flex--column"
                onClick={(element) => {
                  qus_open(element, 8);
                }}
              >
                <div className="Flex Flex--row">
                  <p className="Questions--paragraph-title Typo--h4">
                    What if Dodow doesn't work for me?
                  </p>
                  <button>
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 7L15.2929 12.2929C15.6834 12.6834 15.6834 13.3166 15.2929 13.7071L10 19"
                        stroke="#006666"
                        strokeWidth="3"
                      ></path>
                    </svg>
                  </button>
                </div>
                <p className="Questions--paragraph-text Typo--body">
                  You have 100 days to test Dodow, we will refund your money if
                  you are not satisfied.
                </p>
              </div>

              <div
                className="Questions--paragraph  Flex Flex--column"
                onClick={(element) => {
                  qus_open(element, 9);
                }}
              >
                <div className="Flex Flex--row">
                  <p className="Questions--paragraph-title Typo--h4">
                    Will Dodow turn off by itself?
                  </p>
                  <button>
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 7L15.2929 12.2929C15.6834 12.6834 15.6834 13.3166 15.2929 13.7071L10 19"
                        stroke="#006666"
                        strokeWidth="3"
                      ></path>
                    </svg>
                  </button>
                </div>
                <p className="Questions--paragraph-text Typo--body">
                  Yes, of course, Dodow will turn off by itself at the end of
                  your 8-minute or 20-minute routine, depending on your choice.
                </p>
              </div>
            </div>

            <div className="Questions--paragraph-more">
              <button
                className="Button Button--green Button--fitted Typo--button mx-auto Questions--paragraph-more-btn"
                onClick={(ele) => {
                  if (more_qus.current.style.display == "none") {
                    ele.target.textContent = "Close more questions";
                    more_qus.current.style.display = "block";
                  } else {
                    ele.target.textContent = "View more questions";
                    more_qus.current.style.display = "none";
                  }
                }}
              >
                View more questions
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Questions;
