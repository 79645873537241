import React from "react";
import "./second.css";

function Second() {
  return (
    <div className="Wrap Media--wrap" id="media-section">
      <section className="Container Media">
        <div
          className="splide is-initialized splide--slide splide--ltr splide--draggable is-active"
          id="media-carousel"
        >
          <div className="splide__arrows" >
            <button
              className="splide__arrow Button--slider-arrow Button--slider-arrow-grey splide__arrow--prev"
              type="button"
              aria-controls="media-carousel-track"
              aria-label="Go to last slide"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 40 40"
                width="40"
                height="40"
              >
                <path d="M26.3787 18.5607L19.9393 12.1213L22.0607 10L30.3536 18.2929C31.3299 19.2692 31.3299 20.8521 30.3536 21.8284L22.0607 30.1213L19.9393 28L26.3787 21.5607L9 21.5607V18.5607L26.3787 18.5607Z"></path>
              </svg>
            </button>
            <button
              className="splide__arrow Button--slider-arrow Button--slider-arrow-grey splide__arrow--next"
              type="button"
              aria-controls="media-carousel-track"
              aria-label="Next slide"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 40 40"
                width="40"
                height="40"
              >
                <path d="M26.3787 18.5607L19.9393 12.1213L22.0607 10L30.3536 18.2929C31.3299 19.2692 31.3299 20.8521 30.3536 21.8284L22.0607 30.1213L19.9393 28L26.3787 21.5607L9 21.5607V18.5607L26.3787 18.5607Z"></path>
              </svg>
            </button>
          </div>
          <div
            className="splide__track"
            id="media-carousel-track"
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            <div
              className="splide__list"
              id="media-carousel-list"
  
            >
              <figure
                className="splide__slide Flex Flex--column is-active is-visible"
                id="media-carousel-slide01"
                style={{ width: "calc(100%)" }}
                tabIndex="0"
              >
                <blockquote className="Typo--h3 Flex Flex--center">
                  “Whether you desperately need help settling down one night or
                  want to add to your daily wind-down ritual, this quirky gadget
                  might be your solution [...] We tried the Dodow ourselves and
                  it helped calm us down and get better sleep.”
                </blockquote>
                <figcaption>
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow-media-cnn.svg"
                    loading="lazy"
                    alt="CNN"
                  />
                </figcaption>
              </figure>
              <figure
                className="splide__slide Flex Flex--column is-next"
                id="media-carousel-slide02"
                style={{ width: "calc(100%)" }}
                aria-hidden="true"
              >
                <blockquote className="Typo--h3 Flex Flex--center">
                  "Avoid spending hundreds of dollars on other remedies with
                  Dodow, a simple but effective sleep trainer."
                </blockquote>
                <figcaption>
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow-media-forbes.svg"
                    loading="lazy"
                    alt="Forbes"
                  />
                </figcaption>
              </figure>
              <figure
                className="splide__slide Flex Flex--column"
                id="media-carousel-slide03"
                style={{ width: "calc(100%)" }}
                aria-hidden="true"
              >
                <blockquote className="Typo--h3 Flex Flex--center">
                  “Dodow is a French company with a fresh take on sleep aids.”
                </blockquote>
                <figcaption>
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow-media-businessinsider.svg"
                    loading="lazy"
                    alt="BusinessInsider"
                  />
                </figcaption>
              </figure>
              <figure
                className="splide__slide Flex Flex--column"
                id="media-carousel-slide04"
                style={{ width: "calc(100%)" }}
                aria-hidden="true"
              >
                <blockquote className="Typo--h3 Flex Flex--center">
                  “Strangely enough, it's possible that you may need to re-learn
                  how to sleep. Your saving grace comes in the form of a
                  metronome and light system by Dodow that teaches you how to
                  fall asleep naturally — no Zzzquil needed.”
                </blockquote>
                <figcaption>
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow-media-mashable.svg"
                    loading="lazy"
                    alt="Mashable"
                  />
                </figcaption>
              </figure>
              <figure
                className="splide__slide Flex Flex--column"
                id="media-carousel-slide05"
                style={{ width: "calc(100%)" }}
                aria-hidden="true"
              >
                <blockquote className="Typo--h3 Flex Flex--center">
                  "The effect is hypnotic and even more effective than a
                  sleeping pill."
                </blockquote>
                <figcaption>
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow-media-vogue.svg"
                    loading="lazy"
                    alt="Vogue"
                  />
                </figcaption>
              </figure>
              <figure
                className="splide__slide Flex Flex--column"
                id="media-carousel-slide06"
                style={{ width: "calc(100%)" }}
                aria-hidden="true"
              >
                <blockquote className="Typo--h3 Flex Flex--center">
                  "It projects a calming blue light, which works as a guide for
                  your breathing, and helps you to fall asleep naturally, and on
                  average 2.5 times faster than on your own."
                </blockquote>
                <figcaption>
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow-media-buzzfeed.svg"
                    loading="lazy"
                    alt="BuzzFeed "
                  />
                </figcaption>
              </figure>
              <figure
                className="splide__slide Flex Flex--column"
                id="media-carousel-slide07"
                style={{ width: "calc(100%)" }}
                aria-hidden="true"
              >
                <blockquote className="Typo--h3 Flex Flex--center">
                  “At the end of the 8 or 20 minute cycle, the sleep aid shuts
                  off automatically, leaving you in the perfect place to fall
                  asleep.”
                </blockquote>
                <figcaption>
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow-media-britco.svg"
                    loading="lazy"
                    alt="BritCo"
                  />
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
        <ul className="splide__pagination">
          <li>
            <button
              className="splide__pagination__page"
              type="button"
              aria-controls="media-carousel-slide01"
              aria-label="Go to slide 1"
            ></button>
          </li>
          <li>
            <button
              className="splide__pagination__page"
              type="button"
              aria-controls="media-carousel-slide02"
              aria-label="Go to slide 2"
            ></button>
          </li>
          <li>
            <button
              className="splide__pagination__page is-active"
              type="button"
              aria-controls="media-carousel-slide03"
              aria-label="Go to slide 3"
              aria-current="true"
            ></button>
          </li>
          <li>
            <button
              className="splide__pagination__page"
              type="button"
              aria-controls="media-carousel-slide04"
              aria-label="Go to slide 4"
            ></button>
          </li>
          <li>
            <button
              className="splide__pagination__page"
              type="button"
              aria-controls="media-carousel-slide05"
              aria-label="Go to slide 5"
            ></button>
          </li>
          <li>
            <button
              className="splide__pagination__page"
              type="button"
              aria-controls="media-carousel-slide06"
              aria-label="Go to slide 6"
            ></button>
          </li>
          <li>
            <button
              className="splide__pagination__page"
              type="button"
              aria-controls="media-carousel-slide07"
              aria-label="Go to slide 7"
            ></button>
          </li>
        </ul>
      </section>
    </div>
  );
}

export default Second;
