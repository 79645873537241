import React from "react";
import "./third.css";
import presentation from "./presentation.mp4";

function Third() {
  return (
    <div>
      <div>
        <div
          className="Wrap Video--wrap snipcss-BM1Vc snip-div"
          id="video-section-a"
        >
          <section className="Container Video flex-col snip-section">
            <div className="Video--player-wrap snip-div">
              <i className="Video--player-volume player-volume"></i>
              <video
                src={presentation}
                controls
                autoPlay
                muted
                loop
                loading="lazy"
                className="autoplay fullscreen-on-click"
                poster="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow-video-poster.jpg"
              ></video>
            </div>
            <h2 className="Typo--caption snip-h2">
              Loved and trusted by
              <br></br>
              more than 850,000 users
            </h2>
            <div className="Video--illu-wrap snip-div">
              <img
                src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow-monk.png"
                className="Video--illu snip-img"
                alt=""
                loading="lazy"
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Third;
