import React, { useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper styles
import "swiper/css";
import "./display.css";

import Carousel from "react-bootstrap/Carousel";

function Displayprod() {
  //offers_list
  const pack = {
    offer_1: {
      id: 1,
      name: "Pack 1 Dodow",
      price: 59.99,
      shipping: 5.99,
      get total() {
        return this.price + this.shipping;
      },
    },
    offer_2: {
      id: 2,
      name: "Pack 2 Dodow",
      price: 98.0,
      shipping: 0,
      get total() {
        return this.price + this.shipping;
      },
    },
    offer_3: {
      id: 3,
      name: "Pack 3 Dodow",
      price: 117.0,
      shipping: 0,
      get total() {
        return this.price + this.shipping;
      },
    },
  };

  //extra products

  const extras = {
    ID_2793: {
      id: 2793,
      name: "Dodow Travel Case (x3)",
      unit_price: 27,
      quantity: 1,
    },

    ID_2806: {
      id: 2806,
      name: "Dodow Travel Case (x1)",
      unit_price: 11,
      quantity: 1,
    },
    ID_2830: {
      id: 2830,
      name: "Lifetime warranty for your Dodow",
      unit_price: 12,
      quantity: 1,
    },
    ID_2780: {
      id: 2780,
      name: "Dodow Travel Case (x2)",
      unit_price: 18,
      quantity: 1,
    },
  };

  //  cart state

  const [cart_product, set_cart] = useState({
    pack_id: pack.offer_1.id,
    pack_name: pack.offer_1.name,
    pack_price: pack.offer_1.total,
    extras_price: 0,
    total_price: function () {
      return (this.pack_price + this.extras_price).toFixed(2);
    },
    extras: {},
  });

  //  add button function
  const add_btn = (id) => {
    set_cart((prev_state) => {
      if(prev_state.extras[`ID_${id}`]){
        return {
          ...cart_product,
          extras_price: prev_state.extras_price + extras[`ID_${id}`].unit_price,
          extras: { ...cart_product.extras, [`ID_${id}`]: {...[`ID_${id}`],  quantity: prev_state.extras[`ID_${id}`].quantity++,} },
        };
      }
      return {
        ...cart_product,
        extras_price: prev_state.extras_price + extras[`ID_${id}`].unit_price,
        extras: { ...cart_product.extras, [`ID_${id}`]: extras[`ID_${id}`] },
      };
    });
  };

  //  pack order button function

  const order_btn = (offer_id) => {
    set_cart((prev_state) => {
      return {
        ...prev_state,
        pack_id: pack[`offer_${offer_id}`].id,
        pack_name: pack[`offer_${offer_id}`].name,
        pack_price: pack[`offer_${offer_id}`].total,
      };
    });
  };

  // checkout button function
  const checkout = () => {
    console.log(cart_product);
  };

  // quantity change function
  const qun_btn_plus = (id) => {

    set_cart((prev_state) => {
      return {
        ...cart_product,
        extras_price: prev_state.extras_price + extras[`ID_${id}`].unit_price,
        extras: {
          ...prev_state.extras,
          [`ID_${id}`]: {
            ...prev_state.extras[`ID_${id}`],
            quantity: prev_state.extras[`ID_${id}`].quantity+1,
          },
        },
      };
    });
  };

  const qun_btn_minus = (id) => {
    set_cart((prev_state) => {
      if (prev_state.extras[`ID_${id}`].quantity > 0) {
        return {
          ...cart_product,
          extras_price: prev_state.extras_price - extras[`ID_${id}`].unit_price,
          extras: {
            ...prev_state.extras,
            [`ID_${id}`]: {
              ...prev_state.extras[`ID_${id}`],
              quantity: prev_state.extras[`ID_${id}`].quantity-1,
            },
          },
        };
      } else {
        let temp = { ...prev_state };
        delete temp.extras[`ID_${id}`];
        return {
          ...temp,
          extras_price: prev_state.extras_price - extras[`ID_${id}`].unit_price,
        };
      }
    });
  };

  // button renderer according to the quantity change
  const extras_btn_gen = (id) => {
    if (
      cart_product.extras[`ID_${id}`] === undefined ||
      cart_product.extras[`ID_${id}`] === null
    ) {
      return (
        <button
          id={`add-btn-${id}`}
          data-upsell-id={id}
          className="Upsells--add-text Typo--button-small Button Button--small Button--green justify-content-center   "
          onClick={() => {
            add_btn(id);
          }}
        >
          Add
        </button>
      );
    } else {
      return (
        <div
          id={`add-qty-${id}`}
          className="Typo--button-small Button Button--small Button--default Button-upsell-add snip-div"
        >
          <button
            className="d-inline-block Upsells--add-minus   "
            data-upsell-id={id}
            onClick={() => qun_btn_minus(id)}
          >
            <svg
              width={12}
              height={2}
              viewBox="0 0 12 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="snip-svg"
            >
              <line
                y1={1}
                x2={12}
                y2={1}
                stroke="#006666"
                strokeWidth={2}
              ></line>
            </svg>
          </button>
          <span className="align-middle quantity">
            {cart_product.extras[`ID_${id}`].quantity}
          </span>
          <button
            className="d-inline-block Upsells--add-plus   "
            data-upsell-id={id}
            onClick={() => qun_btn_plus(id)}
          >
            <svg
              width={12}
              height={12}
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="snip-svg"
            >
              <line
                y1={6}
                x2={12}
                y2={6}
                stroke="#006666"
                strokeWidth={2}
              ></line>
              <line
                x1={6}
                y1="4.37114e-08"
                x2={6}
                y2={12}
                stroke="#006666"
                strokeWidth={2}
              ></line>
            </svg>
          </button>
        </div>
      );
    }
  };

  // modal refrence
  const more_info = useRef(null);
  // more info btn modal toggle
  const moreInfo_event = (element, add_toggle) => {

    document.getElementsByTagName("html")[0].style.overflow!=="hidden" ?  document.getElementsByTagName("html")[0].style.overflow="hidden" : document.getElementsByTagName("html")[0].style.overflow="auto";
    more_info.current.children[
      `modal${element.target.dataset.upsellId}`
    ].classList.toggle("hidden");
    console.log(add_toggle);
    if (add_toggle) {
      add_btn(element.target.dataset.upsellId);
    }
  };



  // main return
  return (
    <div>
      <div
        className="Wrap--light snipcss-XM1ew snip-div"
        id="bundle"
        data-save-cart-url="https://www.mydodow.com/save-cart"
      >
        <section className="Container Container--light Bundles--container snip-section">
          <div className="Bundles snip-div">
            {/* top title */}
            <header>
              <h2 className="Typo--h2 snip-h2">Get Dodow Now!</h2>
            </header>
            <div className="Bundles--guarantee snip-div" id="bundles-guarantee">
              <img
                src="https://d3ce15loqxej0z.cloudfront.net/res/shared/en-gb/neutres/dodow-bundle-guarantee.svg"
                alt=""
                loading="lazy"
                className="snip-img"
              />
              <div className="Bundles--guarantee-text Typo--cart-guarantee snip-div">
                <p className="snip-p">
                  Satisfaction guarantee or your money back
                </p>
                <p className="snip-p">100 days to test Dodow</p>
              </div>
            </div>

            {/* package */}
            <div className="Bundles--wrap snip-div">
              <div
                className="Bundles--choice Bundles--choice-1 bundle-cart-trigger snip-div"
                data-bundle-price="59.99"
                data-shipping-price="5.99"
                data-bundle-name="Dodow"
                data-bundle-number={1}
                data-offer-id={1}
              >
                <input
                  type="radio"
                  name="bundle"
                  className="RadioMobile"
                  id="RadioMobile1"
                  defaultChecked
                  onClick={() => {
                    order_btn(1);
                  }}
                />
                <label
                  className="Bundles--item "
                  htmlFor="RadioMobile1"
                  data-bundle-number={1}
                >
                  <img
                    className="Bundles--item-image-mobile snip-img"
                    alt="dodow bundle 1"
                    loading="lazy"
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow2021-bundle-packshot1.jpg"
                  />
                  <div className="Bundles--item-offer snip-div">
                    <div className="Bundles--item-offer-title Typo--h4 snip-div">
                      Pack 1 Dodow
                    </div>
                    <div className="Bundles--item-offer-price-wrap mt-md-2 snip-div">
                      <span className="Bundles--item-offer-price Typo--price-bundle ">
                        $59.99
                      </span>
                    </div>
                    <div className="Bundles--item-offer-unit-price Typo--cart-details snip-div">
                      Unit price: $59.99
                    </div>
                    <div className="Bundles--item-offer-shipping Typo--cart-details snip-div">
                      Shipping: $5.99
                    </div>
                  </div>
                  <a
                    className="Bundles--buy Bundles--desktop-buy bundle-cart-trigger-desktop Typo--button Button Button--large Button--default Button--caret snip-a"
                    id="item-choice-1"
                    data-bundle-number={1}
                    data-offer-id={1}
                    data-bundle-price="59.99"
                    data-shipping-price="5.99"
                    style={{ cursor: "pointer" }}
                  >
                    Order now
                  </a>
                </label>
              </div>
              <div
                className="Bundles--choice Bundles--choice-2 bundle-cart-trigger snip-div"
                data-bundle-price={98}
                data-shipping-price={0}
                data-bundle-name="2 x Dodow"
                data-bundle-number={2}
                data-offer-id={2}
              >
                <input
                  type="radio"
                  name="bundle"
                  className="RadioMobile"
                  id="RadioMobile2"
                  onClick={() => {
                    order_btn(2);
                  }}
                />
                <label
                  className="Bundles--item "
                  htmlFor="RadioMobile2"
                  data-bundle-number={2}
                  id="bundles-item-2"
                >
                  <div className="special-offer-stamp Typo--cart-stamp snip-div">
                    Family offer
                  </div>
                  <img
                    className="Bundles--item-mostpopular snip-img"
                    alt="bundle 2"
                    loading="lazy"
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/shopping/badge_most_popular_en.svg"
                  />
                  <img
                    className="Bundles--item-image-mobile snip-img"
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow2021-bundle-packshot2.jpg"
                    alt="dodow bundle 2"
                    loading="lazy"
                  />
                  <div className="Bundles--item-offer snip-div">
                    <div className="Bundles--item-offer-title Typo--h4 snip-div">
                      Pack 2 Dodow
                    </div>
                    <div className="Bundles--item-offer-price-wrap flex-col mt-md-2 snip-div">
                      <span className="Bundles--item-offer-price Typo--price-bundle ">
                        $98.00
                      </span>
                      <div className="d-flex align-items-center snip-div">
                        <small className="Bundles--item-offer-full-price Typo--price-bundle-small">
                          $118.00
                        </small>
                        <small className="Typo--cart-details ml-1">
                          (17%&nbsp;off)
                        </small>
                      </div>
                    </div>
                    <div className="Bundles--item-offer-unit-price Typo--cart-details snip-div">
                      Unit price: $49.00
                    </div>
                    <div className="Bundles--item-offer-shipping Typo--price-bundle-small snip-div">
                      Free shipping
                    </div>
                  </div>
                  <a
                    className="Bundles--buy Bundles--desktop-buy bundle-cart-trigger-desktop Typo--button Button Button--large Button--default Button--caret snip-a"
                    id="item-choice-2"
                    data-bundle-number={2}
                    data-offer-id={2}
                    data-bundle-price={98}
                    data-shipping-price={0}
                    style={{ cursor: "pointer" }}
                  >
                    Order now
                  </a>
                </label>
              </div>
              <div
                className="Bundles--choice Bundles--choice-3 bundle-cart-trigger snip-div"
                data-bundle-price={117}
                data-shipping-price={0}
                data-bundle-name="3 x Dodow"
                data-bundle-number={3}
                data-offer-id={3}
              >
                <input
                  type="radio"
                  name="bundle"
                  className="RadioMobile"
                  id="RadioMobile3"
                  onClick={() => {
                    order_btn(3);
                  }}
                />
                <label
                  className="Bundles--item  "
                  htmlFor="RadioMobile3"
                  data-bundle-number={3}
                >
                  <img
                    className="Bundles--item-image-mobile snip-img"
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow2021-bundle-packshot3.jpg"
                    alt="dodow bundle 3"
                    loading="lazy"
                  />
                  <div className="Bundles--item-offer snip-div">
                    <div className="Bundles--item-offer-title Typo--h4 snip-div">
                      Pack 3 Dodow
                    </div>
                    <div className="Bundles--item-offer-price-wrap flex-col mt-md-2 snip-div">
                      <span className="Bundles--item-offer-price Typo--price-bundle ">
                        $117.00
                      </span>
                      <div className="d-flex align-items-center snip-div">
                        <small className="Bundles--item-offer-full-price Typo--price-bundle-small">
                          $177.00
                        </small>
                        <small className="Typo--cart-details ml-1">
                          (34%&nbsp;off)
                        </small>
                      </div>
                    </div>
                    <div className="Bundles--item-offer-unit-price Typo--cart-details snip-div">
                      Unit price: $39.00
                    </div>
                    <div className="Bundles--item-offer-shipping Typo--price-bundle-small snip-div">
                      Free shipping
                    </div>
                  </div>
                  <a
                    className="Bundles--buy Bundles--desktop-buy bundle-cart-trigger-desktop Typo--button Button Button--large Button--default Button--caret snip-a"
                    id="item-choice-3"
                    data-bundle-number={3}
                    data-offer-id={3}
                    data-bundle-price={117}
                    data-shipping-price={0}
                    onClick={() => {
                      order_btn(3);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Order now
                  </a>
                </label>
              </div>
            </div>
          </div>
        </section>

        {/* bottom panel */}
        <section
          className={`Upsells--wrap Upsells--open bundle-selected-${cart_product.pack_id}  snip-section`}
          id="bundle-upsells-block"
        >
          <h2 className=" Container Upsells--title Typo--intertitle snip-h2">
            Become a sleep hero with our family
          </h2>
          <div className="Upsells Container pb-md-0 snip-div">
            <div
              className="Upsells--slider splide is-initialized snip-div"
              id="upsells-main-slider"
              data-upsells-count={4}
            >
              <div
                className=" splide__track snip-div slide_item"
                id="upsells-main-slider-track "
              >
                <Swiper
                  breakpoints={{
                    781: {
                      slidesPerView: 3,
                    },
                  }}
                  onSwiper={(swiper) => {
                    if (window.innerWidth > 992) {
                      swiper.disable();
                    }
                  }}
                  className=" splide__list slider-disabled snip-ul ul_list slide_item"
                  id="main-upsells-slider-list"
                >
                  <SwiperSlide
                    className=" Upsells--item splide__slide upsell2793 snip-li slide_item_list"
                    data-upsell-price={27}
                    data-upsell-priority1={2}
                    data-upsell-priority2={2}
                    data-upsell-priority3={14}
                    id="upsells-main-slider-slide01"
                    style={{}}
                  >
                    <div className="d-flex justify-content-center snip-div">
                      <img
                        className="Upsells--item-image snip-img"
                        data-upsell-id={2793}
                        src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x3-540x540-min.png"
                        srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x3-540x540-min_thumb_115.png 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x3-540x540-min_thumb_135.png 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x3-540x540-min_thumb_150.png 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x3-540x540-min_thumb_300.png 300w"
                        sizes="(max-width: 768px) 50vw, 25rem"
                        alt="Dodow Travel Case (x3)"
                        loading="lazy"
                      />
                    </div>
                    <div className="Upsells--item-desc snip-div">
                      <div className="snip-div">
                        <div className="Upsells--item-desc-title Typo--h4 snip-div">
                          Dodow Travel Case (x3)
                        </div>
                        <div className="Upsells--item-desc-offer flex-row mt-md-1 mb-2 mb-md-3 snip-div">
                          <span className="Upsells--item-desc-offer-price  Typo--h4 mr-1">
                            $27
                          </span>
                          <small className="Upsells--item-desc-offer-full-price Typo--h4">
                            $57
                          </small>
                        </div>
                      </div>
                      <div className="w-100 flex-col snip-div">
                        {extras_btn_gen(2793)}

                        <button
                          className="Upsells--item-info Typo--body-small   "
                          data-upsell-id={2793}
                          onClick={(element) => {
                            moreInfo_event(element, false);
                          }}
                        >
                          More info
                        </button>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide
                    className=" Upsells--item splide__slide upsell2830 snip-li slide_item_list"
                    data-upsell-price={12}
                    data-upsell-priority1={6}
                    data-upsell-priority2={5}
                    data-upsell-priority3={5}
                    id="upsells-main-slider-slide02"
                    style={{}}
                  >
                    <div className="d-flex justify-content-center snip-div">
                      <img
                        className="Upsells--item-image snip-img"
                        data-upsell-id={2830}
                        src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-lifetimewarrantybadge-540x540-vf-min.png"
                        srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-lifetimewarrantybadge-540x540-vf-min_thumb_115.png 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-lifetimewarrantybadge-540x540-vf-min_thumb_135.png 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-lifetimewarrantybadge-540x540-vf-min_thumb_150.png 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-lifetimewarrantybadge-540x540-vf-min_thumb_300.png 300w"
                        sizes="(max-width: 768px) 50vw, 25rem"
                        alt="Lifetime warranty for your Dodow"
                        loading="lazy"
                      />
                    </div>
                    <div className="Upsells--item-desc snip-div">
                      <div className="snip-div">
                        <div className="Upsells--item-desc-title Typo--h4 snip-div">
                          Lifetime warranty for your Dodow
                        </div>
                        <div className="Upsells--item-desc-offer flex-row mt-md-1 mb-2 mb-md-3 snip-div">
                          <span className="Upsells--item-desc-offer-price  Typo--h4">
                            $12
                          </span>
                        </div>
                      </div>
                      <div className="w-100 flex-col snip-div">
                        {extras_btn_gen(2830)}
                        <button
                          className="Upsells--item-info Typo--body-small   "
                          data-upsell-id={2830}
                          onClick={(element) => {
                            moreInfo_event(element, false);
                          }}
                        >
                          More info
                        </button>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide
                    className=" Upsells--item splide__slide upsell2806 snip-li slide_item_list"
                    data-upsell-price={11}
                    data-upsell-priority1={14}
                    data-upsell-priority2={4}
                    data-upsell-priority3={4}
                    id="upsells-main-slider-slide03"
                    style={{}}
                  >
                    <div className="d-flex justify-content-center snip-div">
                      <img
                        className="Upsells--item-image snip-img"
                        data-upsell-id={2806}
                        src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x1-540x540-min.png"
                        srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x1-540x540-min_thumb_115.png 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x1-540x540-min_thumb_135.png 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x1-540x540-min_thumb_150.png 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x1-540x540-min_thumb_300.png 300w"
                        sizes="(max-width: 768px) 50vw, 25rem"
                        alt="Dodow Travel Case (x1)"
                        loading="lazy"
                      />
                    </div>
                    <div className="Upsells--item-desc snip-div">
                      <div className="snip-div">
                        <div className="Upsells--item-desc-title Typo--h4 snip-div">
                          Dodow Travel Case (x1)
                        </div>
                        <div className="Upsells--item-desc-offer flex-row mt-md-1 mb-2 mb-md-3 snip-div">
                          <span className="Upsells--item-desc-offer-price  Typo--h4 mr-1">
                            $11
                          </span>
                          <small className="Upsells--item-desc-offer-full-price Typo--h4">
                            $19
                          </small>
                        </div>
                      </div>
                      <div className="w-100 flex-col snip-div">
                        {extras_btn_gen(2806)}
                        <button
                          className="Upsells--item-info Typo--body-small   "
                          data-upsell-id={2806}
                          onClick={(element) => {
                            moreInfo_event(element, false);
                          }}
                        >
                          More info
                        </button>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide
                    className=" Upsells--item splide__slide upsell2780 snip-li slide_item_list"
                    data-upsell-price={18}
                    data-upsell-priority1={4}
                    data-upsell-priority2={14}
                    data-upsell-priority3={2}
                    id="upsells-main-slider-slide04"
                    style={{}}
                  >
                    <div className="d-flex justify-content-center snip-div">
                      <img
                        className="Upsells--item-image snip-img"
                        data-upsell-id={2780}
                        src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x2-540x540-min.png"
                        srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x2-540x540-min_thumb_115.png 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x2-540x540-min_thumb_135.png 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x2-540x540-min_thumb_150.png 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x2-540x540-min_thumb_300.png 300w"
                        sizes="(max-width: 768px) 50vw, 25rem"
                        alt="Dodow Travel Case (x2)"
                        loading="lazy"
                      />
                    </div>
                    <div className="Upsells--item-desc snip-div">
                      <div className="snip-div">
                        <div className="Upsells--item-desc-title Typo--h4 snip-div">
                          Dodow Travel Case (x2)
                        </div>
                        <div className="Upsells--item-desc-offer flex-row mt-md-1 mb-2 mb-md-3 snip-div">
                          <span className="Upsells--item-desc-offer-price  Typo--h4 mr-1">
                            $18
                          </span>
                          <small className="Upsells--item-desc-offer-full-price Typo--h4">
                            $38
                          </small>
                        </div>
                      </div>
                      <div className="w-100 flex-col snip-div">
                        {extras_btn_gen(2780)}
                        <button
                          className="Upsells--item-info Typo--body-small   "
                          data-upsell-id={2780}
                          onClick={(element) => {
                            moreInfo_event(element, false);
                          }}
                        >
                          More info
                        </button>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>

          {/* more info model */}
          <div ref={more_info}>
            <div
              className="Upsells--modal hidden Popup--overlay snip-div"
              id="modal2793"
              data-upsell-id={2793}
            >
              <div className="Upsells--modal-content Popup Popup--narrow snip-div">
                <div className="Upsells--modal-header snip-div">
                  <div
                    className="Upsells--modal-slider-main snip-div"
                    id="upsells-modal-main-slider-2793"
                    data-upsell-id={2793}
                  >
                    <div className="splide__track snip-div">
                      <div
                        id="model1"
                        className="splide__list snip-div carousel slide"
                        data-bs-ride="carousel"
                        data-bs-touch="true"
                        data-bs-interval={5000}
                      >
                        <div className="carousel-indicators ">
                          <button
                            type="button"
                            data-bs-target="#model1"
                            data-bs-slide-to="0"
                            className="active"
                            aria-current="true"
                            aria-label="Slide 1"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#model1"
                            data-bs-slide-to="1"
                            aria-label="Slide 2"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#model1"
                            data-bs-slide-to="2"
                            aria-label="Slide 3"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#model1"
                            data-bs-slide-to="3"
                            aria-label="Slide 4"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#model1"
                            data-bs-slide-to="4"
                            aria-label="Slide 5"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#model1"
                            data-bs-slide-to="5"
                            aria-label="Slide 6"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#model1"
                            data-bs-slide-to="6"
                            aria-label="Slide 7"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#model1"
                            data-bs-slide-to="7"
                            aria-label="Slide 8"
                          ></button>
                        </div>
                        <div className="carousel-inner ">
                          <div className="carousel-item active">
                            <img
                              className="Upsells--modal-slider-main-item splide__slide snip-img"
                              src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x3-540x540-min.png"
                              loading="lazy"
                              sizes="(min-width: 768px) 50vw, 100vw"
                              srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x3-540x540-min.png 540w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x3-540x540-min_thumb_115.png 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x3-540x540-min_thumb_135.png 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x3-540x540-min_thumb_150.png 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x3-540x540-min_thumb_300.png 300w"
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              className="Upsells--modal-slider-main-item splide__slide snip-img"
                              src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case1.jpg"
                              loading="lazy"
                              sizes="(min-width: 768px) 50vw, 100vw"
                              srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case1.jpg 540w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case1_thumb_115.jpg 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case1_thumb_135.jpg 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case1_thumb_150.jpg 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case1_thumb_300.jpg 300w"
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              className="Upsells--modal-slider-main-item splide__slide snip-img"
                              src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case3.jpg"
                              loading="lazy"
                              sizes="(min-width: 768px) 50vw, 100vw"
                              srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case3.jpg 540w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case3_thumb_115.jpg 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case3_thumb_135.jpg 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case3_thumb_150.jpg 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case3_thumb_300.jpg 300w"
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              className="Upsells--modal-slider-main-item splide__slide snip-img"
                              src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case4.jpg"
                              loading="lazy"
                              sizes="(min-width: 768px) 50vw, 100vw"
                              srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case4.jpg 540w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case4_thumb_115.jpg 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case4_thumb_135.jpg 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case4_thumb_150.jpg 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case4_thumb_300.jpg 300w"
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              className="Upsells--modal-slider-main-item splide__slide snip-img"
                              src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case5.jpg"
                              loading="lazy"
                              sizes="(min-width: 768px) 50vw, 100vw"
                              srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case5.jpg 540w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case5_thumb_115.jpg 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case5_thumb_135.jpg 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case5_thumb_150.jpg 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case5_thumb_300.jpg 300w"
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              className="Upsells--modal-slider-main-item splide__slide snip-img"
                              src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case6.jpg"
                              loading="lazy"
                              sizes="(min-width: 768px) 50vw, 100vw"
                              srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case6.jpg 540w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case6_thumb_115.jpg 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case6_thumb_135.jpg 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case6_thumb_150.jpg 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case6_thumb_300.jpg 300w"
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              className="Upsells--modal-slider-main-item splide__slide snip-img"
                              src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case7.jpg"
                              loading="lazy"
                              sizes="(min-width: 768px) 50vw, 100vw"
                              srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case7.jpg 540w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case7_thumb_115.jpg 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case7_thumb_135.jpg 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case7_thumb_150.jpg 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case7_thumb_300.jpg 300w"
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              className="Upsells--modal-slider-main-item splide__slide snip-img"
                              src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case8.jpg"
                              loading="lazy"
                              sizes="(min-width: 768px) 50vw, 100vw"
                              srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case8.jpg 540w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case8_thumb_115.jpg 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case8_thumb_135.jpg 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case8_thumb_150.jpg 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case8_thumb_300.jpg 300w"
                            />
                          </div>
                        </div>
                        <button
                          className="carousel-control-prev img-carousel-control"
                          type="button"
                          data-bs-target="#model1"
                          data-bs-slide="prev"
                        >
                          <span
                            className="carousel-control-prev-icon img-control-prev-icon "
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                          className="carousel-control-next img-carousel-control"
                          type="button"
                          data-bs-target="#model1"
                          data-bs-slide="next"
                        >
                          <span
                            className="carousel-control-next-icon img-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Next</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="Upsells--modal-slider-nav snip-div"
                    id="upsells-modal-nav-slider-2793"
                    data-upsell-id={2793}
                  ></div>
                  <button
                    type="button"
                    className="Upsells--modal-close Button--close-popup   "
                    data-upsell-id={2793}
                    onClick={(element) => {
                      moreInfo_event(element, false);
                    }}
                  ></button>
                </div>
                <div className="Upsells--modal-desc Popup--body snip-div">
                  <div className="snip-div">
                    <h2 className="Upsells--modal-desc-title Typo--upsell-popup-title snip-h2">
                      Dodow Travel Case (x3)
                    </h2>
                    <div
                      className="Upsells--modal-desc-text Typo--body snip-div"
                      data-upsell-id={2793}
                    >
                      <p className="snip-p">
                        <span style={{ fontWeight: "bolder" }}>
                          Especially designed for your Dodow
                        </span>
                        <br />
                        This purpose-built case will secure your Dodow in place,
                        so that it won't switch on during your trip. Ideal to
                        get to sleep everywhere you go.
                      </p>
                      <p className="snip-p">
                        <span style={{ fontWeight: "bolder" }}>
                          100% Safe inside
                        </span>
                        <br />
                        Thanks to its high density EVA foam, Dodow's case is
                        water-resistant and shockproof, which allows you to
                        travel anytime and anywhere with your Dodow. The case
                        protects your Dodow from scratches and dusts. Rock solid
                        !
                      </p>
                    </div>
                    <div className="Upsells--modal-add-overlay snip-div"></div>
                  </div>
                  <button
                    className="Upsells--modal-add Typo--button Button Button--green justify-content-center   "
                    id="add-btn-modal-2793"
                    data-upsell-id={2793}
                    onClick={(element) => {
                      moreInfo_event(element, true);
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
            <div
              className="Upsells--modal hidden Popup--overlay snip-div"
              id="modal2830"
              data-upsell-id={2830}
            >
              <div className="Upsells--modal-content Popup Popup--narrow snip-div">
                <div className="Upsells--modal-header snip-div">
                  <div
                    className="Upsells--modal-slider-main snip-div"
                    id="upsells-modal-main-slider-2830"
                    data-upsell-id={2830}
                  >
                    <div className="splide__track snip-div">
                      <div className="splide__list snip-div">
                        <img
                          className="Upsells--modal-slider-main-item splide__slide snip-img"
                          src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-lifetimewarrantybadge-540x540-vf-min.png"
                          loading="lazy"
                          sizes="(min-width: 768px) 50vw, 100vw"
                          srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-lifetimewarrantybadge-540x540-vf-min.png 540w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-lifetimewarrantybadge-540x540-vf-min_thumb_115.png 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-lifetimewarrantybadge-540x540-vf-min_thumb_135.png 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-lifetimewarrantybadge-540x540-vf-min_thumb_150.png 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-lifetimewarrantybadge-540x540-vf-min_thumb_300.png 300w"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="Upsells--modal-slider-nav snip-div"
                    id="upsells-modal-nav-slider-2830"
                    data-upsell-id={2830}
                  ></div>
                  <button
                    type="button"
                    className="Upsells--modal-close Button--close-popup   "
                    data-upsell-id={2830}
                    onClick={(element) => {
                      moreInfo_event(element, false);
                    }}
                  ></button>
                </div>
                <div className="Upsells--modal-desc Popup--body snip-div">
                  <div className="snip-div">
                    <h2 className="Upsells--modal-desc-title Typo--upsell-popup-title snip-h2">
                      Lifetime warranty for your Dodow
                    </h2>
                    <div
                      className="Upsells--modal-desc-text Typo--body snip-div"
                      data-upsell-id={2830}
                    >
                      <p className="snip-p">
                        <strong>Article 11: Legal conformity guarantee</strong>
                      </p>
                      <br />
                      <p className="snip-p">
                        Any Dodow, whether it be purchased via the Website or
                        from a third party, is guaranteed by the statutory legal
                        conformity guarantee against any underlying technical
                        defect discovered during normal use of the article for a
                        period of twenty-four (24) months. Any request for
                        return under this Article must first be communicated to
                        and approved by LIVLAB (by sending a message to our
                        Support Team through our
                        <a
                          href="https://intercom.help/livlab"
                          className="snip-a"
                        >
                          Help Center
                        </a>
                        ).&nbsp;
                      </p>
                      <br />
                      <p className="snip-p">
                        <strong>
                          With lifetime warranty, this guarantee is extended for
                          an unlimited duration.
                        </strong>
                      </p>
                      <br />
                      <p className="snip-p">
                        The customer must return the delivered item in its
                        original packaging with all the accessories provided
                        from the outset. The customer also accepts to provide
                        proof of postage, with a valid tracking number. LIVLAB
                        undertakes to cover any return fees incurred by the
                        customer under this Article.&nbsp;
                      </p>
                      <br />
                      <p className="snip-p">
                        LIVLAB will address the new product to the customer once
                        the original item has been received. Should the product
                        no longer be in stock or if the product cannot be
                        repaired, LIVLAB will offer a similar product to the
                        customer or a reimbursement of the initial product
                        price.
                      </p>
                    </div>
                    <div className="Upsells--modal-add-overlay snip-div"></div>
                  </div>
                  <button
                    className="Upsells--modal-add Typo--button Button Button--green justify-content-center   "
                    id="add-btn-modal-2830"
                    data-upsell-id={2830}
                    onClick={(element) => {
                      moreInfo_event(element, true);
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
            <div
              className="Upsells--modal hidden Popup--overlay snip-div"
              id="modal2806"
              data-upsell-id={2806}
            >
              <div className="Upsells--modal-content Popup Popup--narrow snip-div">
                <div className="Upsells--modal-header snip-div">
                  <div
                    className="Upsells--modal-slider-main snip-div"
                    id="upsells-modal-main-slider-2806"
                    data-upsell-id={2806}
                  >
                    <div className="splide__track snip-div">
                      <div
                        id="model2"
                        className="splide__list snip-div carousel slide"
                        data-bs-ride="carousel"
                        data-bs-touch="true"
                        data-bs-interval={5000}
                      >
                        <div className="carousel-indicators ">
                          <button
                            type="button"
                            data-bs-target="#model2"
                            data-bs-slide-to="0"
                            className="active"
                            aria-current="true"
                            aria-label="Slide 1"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#model2"
                            data-bs-slide-to="1"
                            aria-label="Slide 2"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#model2"
                            data-bs-slide-to="2"
                            aria-label="Slide 3"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#model2"
                            data-bs-slide-to="3"
                            aria-label="Slide 4"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#model2"
                            data-bs-slide-to="4"
                            aria-label="Slide 5"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#model2"
                            data-bs-slide-to="5"
                            aria-label="Slide 6"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#model2"
                            data-bs-slide-to="6"
                            aria-label="Slide 7"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#model2"
                            data-bs-slide-to="7"
                            aria-label="Slide 8"
                          ></button>
                        </div>
                        <div className="carousel-inner ">
                          <div className="carousel-item active">
                            <img
                              className="Upsells--modal-slider-main-item splide__slide snip-img"
                              src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x1-540x540-min.png"
                              loading="lazy"
                              sizes="(min-width: 768px) 50vw, 100vw"
                              srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x1-540x540-min.png 540w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x1-540x540-min_thumb_115.png 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x1-540x540-min_thumb_135.png 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x1-540x540-min_thumb_150.png 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x1-540x540-min_thumb_300.png 300w"
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              className="Upsells--modal-slider-main-item splide__slide snip-img"
                              src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case1.jpg"
                              loading="lazy"
                              sizes="(min-width: 768px) 50vw, 100vw"
                              srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case1.jpg 540w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case1_thumb_115.jpg 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case1_thumb_135.jpg 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case1_thumb_150.jpg 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case1_thumb_300.jpg 300w"
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              className="Upsells--modal-slider-main-item splide__slide snip-img"
                              src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case3.jpg"
                              loading="lazy"
                              sizes="(min-width: 768px) 50vw, 100vw"
                              srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case3.jpg 540w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case3_thumb_115.jpg 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case3_thumb_135.jpg 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case3_thumb_150.jpg 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case3_thumb_300.jpg 300w"
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              className="Upsells--modal-slider-main-item splide__slide snip-img"
                              src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case4.jpg"
                              loading="lazy"
                              sizes="(min-width: 768px) 50vw, 100vw"
                              srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case4.jpg 540w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case4_thumb_115.jpg 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case4_thumb_135.jpg 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case4_thumb_150.jpg 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case4_thumb_300.jpg 300w"
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              className="Upsells--modal-slider-main-item splide__slide snip-img"
                              src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case5.jpg"
                              loading="lazy"
                              sizes="(min-width: 768px) 50vw, 100vw"
                              srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case5.jpg 540w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case5_thumb_115.jpg 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case5_thumb_135.jpg 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case5_thumb_150.jpg 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case5_thumb_300.jpg 300w"
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              className="Upsells--modal-slider-main-item splide__slide snip-img"
                              src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case6.jpg"
                              loading="lazy"
                              sizes="(min-width: 768px) 50vw, 100vw"
                              srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case6.jpg 540w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case6_thumb_115.jpg 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case6_thumb_135.jpg 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case6_thumb_150.jpg 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case6_thumb_300.jpg 300w"
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              className="Upsells--modal-slider-main-item splide__slide snip-img"
                              src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case7.jpg"
                              loading="lazy"
                              sizes="(min-width: 768px) 50vw, 100vw"
                              srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case7.jpg 540w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case7_thumb_115.jpg 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case7_thumb_135.jpg 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case7_thumb_150.jpg 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case7_thumb_300.jpg 300w"
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              className="Upsells--modal-slider-main-item splide__slide snip-img"
                              src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case8.jpg"
                              loading="lazy"
                              sizes="(min-width: 768px) 50vw, 100vw"
                              srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case8.jpg 540w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case8_thumb_115.jpg 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case8_thumb_135.jpg 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case8_thumb_150.jpg 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case8_thumb_300.jpg 300w"
                            />
                          </div>
                        </div>
                        <button
                          className="carousel-control-prev img-carousel-control"
                          type="button"
                          data-bs-target="#model2"
                          data-bs-slide="prev"
                        >
                          <span
                            className="carousel-control-prev-icon img-control-prev-icon "
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                          className="carousel-control-next img-carousel-control"
                          type="button"
                          data-bs-target="#model2"
                          data-bs-slide="next"
                        >
                          <span
                            className="carousel-control-next-icon img-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Next</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="Upsells--modal-slider-nav snip-div"
                    id="upsells-modal-nav-slider-2806"
                    data-upsell-id={2806}
                  ></div>
                  <button
                    type="button"
                    className="Upsells--modal-close Button--close-popup   "
                    data-upsell-id={2806}
                    onClick={(element) => {
                      moreInfo_event(element, false);
                    }}
                  ></button>
                </div>

                <div className="Upsells--modal-desc Popup--body snip-div">
                  <div className="snip-div">
                    <h2 className="Upsells--modal-desc-title Typo--upsell-popup-title snip-h2">
                      Dodow Travel Case (x1)
                    </h2>
                    <div
                      className="Upsells--modal-desc-text Typo--body snip-div"
                      data-upsell-id={2806}
                    >
                      <p className="snip-p">
                        <strong>Especially designed for your Dodow</strong>
                        <br />
                        This purpose-built case will secure your Dodow in place,
                        so that it won't switch on during your trip. Ideal to
                        get to sleep everywhere you go.
                      </p>
                      <p className="snip-p">
                        <strong>100% Safe inside</strong>
                        <br />
                        Thanks to its high density EVA foam, Dodow's case is
                        water-resistant and shockproof, which allows you to
                        travel anytime and anywhere with your Dodow. The case
                        protects your Dodow from scratches and dusts. Rock solid
                        !
                      </p>
                    </div>
                    <div className="Upsells--modal-add-overlay snip-div"></div>
                  </div>
                  <button
                    className="Upsells--modal-add Typo--button Button Button--green justify-content-center   "
                    id="add-btn-modal-2806"
                    data-upsell-id={2806}
                    onClick={(element) => {
                      moreInfo_event(element, true);
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
            <div
              className="Upsells--modal hidden Popup--overlay snip-div"
              id="modal2780"
              data-upsell-id={2780}
            >
              <div className="Upsells--modal-content Popup Popup--narrow snip-div">
                <div className="Upsells--modal-header snip-div">
                  <div
                    className="Upsells--modal-slider-main snip-div"
                    id="upsells-modal-main-slider-2780"
                    data-upsell-id={2780}
                  >
                    <div className="splide__track snip-div">
                      <div
                        id="model3"
                        className="splide__list snip-div carousel slide"
                        data-bs-ride="carousel"
                        data-bs-touch="true"
                        data-bs-interval={5000}
                      >
                        <div className="carousel-indicators ">
                          <button
                            type="button"
                            data-bs-target="#model3"
                            data-bs-slide-to="0"
                            className="active"
                            aria-current="true"
                            aria-label="Slide 1"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#model3"
                            data-bs-slide-to="1"
                            aria-label="Slide 2"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#model3"
                            data-bs-slide-to="2"
                            aria-label="Slide 3"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#model3"
                            data-bs-slide-to="3"
                            aria-label="Slide 4"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#model3"
                            data-bs-slide-to="4"
                            aria-label="Slide 5"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#model3"
                            data-bs-slide-to="5"
                            aria-label="Slide 6"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#model3"
                            data-bs-slide-to="6"
                            aria-label="Slide 7"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#model3"
                            data-bs-slide-to="7"
                            aria-label="Slide 8"
                          ></button>
                        </div>
                        <div className="carousel-inner ">
                          <div className="carousel-item active">
                            <img
                              className="Upsells--modal-slider-main-item splide__slide snip-img"
                              src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x2-540x540-min.png"
                              loading="lazy"
                              sizes="(min-width: 768px) 50vw, 100vw"
                              srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x2-540x540-min.png 540w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x2-540x540-min_thumb_115.png 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x2-540x540-min_thumb_135.png 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x2-540x540-min_thumb_150.png 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Upsells_New_202105/dodow-dodowcase-x2-540x540-min_thumb_300.png 300w"
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              className="Upsells--modal-slider-main-item splide__slide snip-img"
                              src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case1.jpg"
                              loading="lazy"
                              sizes="(min-width: 768px) 50vw, 100vw"
                              srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case1.jpg 540w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case1_thumb_115.jpg 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case1_thumb_135.jpg 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case1_thumb_150.jpg 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case1_thumb_300.jpg 300w"
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              className="Upsells--modal-slider-main-item splide__slide snip-img"
                              src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case3.jpg"
                              loading="lazy"
                              sizes="(min-width: 768px) 50vw, 100vw"
                              srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case3.jpg 540w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case3_thumb_115.jpg 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case3_thumb_135.jpg 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case3_thumb_150.jpg 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case3_thumb_300.jpg 300w"
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              className="Upsells--modal-slider-main-item splide__slide snip-img"
                              src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case4.jpg"
                              loading="lazy"
                              sizes="(min-width: 768px) 50vw, 100vw"
                              srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case4.jpg 540w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case4_thumb_115.jpg 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case4_thumb_135.jpg 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case4_thumb_150.jpg 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case4_thumb_300.jpg 300w"
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              className="Upsells--modal-slider-main-item splide__slide snip-img"
                              src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case5.jpg"
                              loading="lazy"
                              sizes="(min-width: 768px) 50vw, 100vw"
                              srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case5.jpg 540w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case5_thumb_115.jpg 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case5_thumb_135.jpg 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case5_thumb_150.jpg 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case5_thumb_300.jpg 300w"
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              className="Upsells--modal-slider-main-item splide__slide snip-img"
                              src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case6.jpg"
                              loading="lazy"
                              sizes="(min-width: 768px) 50vw, 100vw"
                              srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case6.jpg 540w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case6_thumb_115.jpg 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case6_thumb_135.jpg 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case6_thumb_150.jpg 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case6_thumb_300.jpg 300w"
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              className="Upsells--modal-slider-main-item splide__slide snip-img"
                              src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case7.jpg"
                              loading="lazy"
                              sizes="(min-width: 768px) 50vw, 100vw"
                              srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case7.jpg 540w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case7_thumb_115.jpg 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case7_thumb_135.jpg 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case7_thumb_150.jpg 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case7_thumb_300.jpg 300w"
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              className="Upsells--modal-slider-main-item splide__slide snip-img"
                              src="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case8.jpg"
                              loading="lazy"
                              sizes="(min-width: 768px) 50vw, 100vw"
                              srcSet="https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case8.jpg 540w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case8_thumb_115.jpg 115w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case8_thumb_135.jpg 135w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case8_thumb_150.jpg 150w, https://d3ce15loqxej0z.cloudfront.net/res/shared/Products/Case8_thumb_300.jpg 300w"
                            />
                          </div>
                        </div>
                        <button
                          className="carousel-control-prev img-carousel-control"
                          type="button"
                          data-bs-target="#model3"
                          data-bs-slide="prev"
                        >
                          <span
                            className="carousel-control-prev-icon img-control-prev-icon "
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                          className="carousel-control-next img-carousel-control"
                          type="button"
                          data-bs-target="#model3"
                          data-bs-slide="next"
                        >
                          <span
                            className="carousel-control-next-icon img-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Next</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="Upsells--modal-slider-nav snip-div"
                    id="upsells-modal-nav-slider-2780"
                    data-upsell-id={2780}
                  ></div>
                  <button
                    type="button"
                    className="Upsells--modal-close Button--close-popup   "
                    data-upsell-id={2780}
                    onClick={(element) => {
                      moreInfo_event(element, false);
                    }}
                  ></button>
                </div>
                <div className="Upsells--modal-desc Popup--body snip-div">
                  <div className="snip-div">
                    <h2 className="Upsells--modal-desc-title Typo--upsell-popup-title snip-h2">
                      Dodow Travel Case (x2)
                    </h2>
                    <div
                      className="Upsells--modal-desc-text Typo--body snip-div"
                      data-upsell-id={2780}
                    >
                      <p className="snip-p">
                        <span style={{ fontWeight: "bolder" }}>
                          Especially designed for your Dodow
                        </span>
                        <br />
                        This purpose-built case will secure your Dodow in place,
                        so that it won't switch on during your trip. Ideal to
                        get to sleep everywhere you go.
                      </p>
                      <p className="snip-p">
                        <span style={{ fontWeight: "bolder" }}>
                          100% Safe inside
                        </span>
                        <br />
                        Thanks to its high density EVA foam, Dodow's case is
                        water-resistant and shockproof, which allows you to
                        travel anytime and anywhere with your Dodow. The case
                        protects your Dodow from scratches and dusts. Rock solid
                        !
                      </p>
                    </div>
                    <div className="Upsells--modal-add-overlay snip-div"></div>
                  </div>
                  <button
                    className="Upsells--modal-add Typo--button Button Button--green justify-content-center   "
                    id="add-btn-modal-2780"
                    data-upsell-id={2780}
                    onClick={(element) => {
                      moreInfo_event(element, true);
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="Bundles--guarantee-wrap Container snip-section"
          style={{ cursor: "pointer" }}
        >
          <div className="d-flex justify-content-center snip-div">
            <a
              id="main-bundles-buy-btn"
              data-bundle-number={3}
              data-currency-symbol="$"
              data-cart-value={98}
              data-bundle-name="2 x Dodow"
              className="btn-to-cart-upsells Bundles--buy Typo--button Button Button--large Button--default Button--icon mx-auto bundle-cart-trigger bundle-cart-trigger-desktop snip-a"
              onClick={checkout}
            >
              Checkout
              <span className="totalCart"> ${cart_product.total_price()}</span>
            </a>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Displayprod;
