import React from "react";
import "./created.css";

function Created() {
  return (
    <div>
      <div className="Wrap--light snipcss-3qcoy snip-div" id="history">
        <section className="Container History snip-section">
          <header>
            <h2 className="Typo--h2 snip-h2">Created by insomniacs</h2>
          </header>
          <div className="flex-col snip-div">
            <div
              className="History--player-wrap snip-div"
              id="history-video-player"
            >
              <i className="History--player-volume player-volume"></i>
              <video
                src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/videos/en-us/dodow2021_history_video_en-us.mp4"
                autoPlay
                muted
                loop
                controls
                loading="lazy"
                className="autoplay fullscreen-on-click"
                poster="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow-history-video-poster-en.jpg"
              ></video>
            </div>
            <div className="History--text Typo--body snip-div">
              <p className="snip-p">
                The danger of sleeping pills led Alex, a chronic insomniac, to
                look for other ways to fall asleep. He discovered that the
                control of breathing is at the heart of practices such as
                meditation, yoga, Sophrology and cardiac coherence.
              </p>
              <p className="snip-p">
                Numerous studies have shown that breathing is ultimately one of
                the best tools for regulating stress and mental restlessness!
                Long months of meditation practice have definitively solved his
                problems.
              </p>
              <p className="snip-p">
                Accompanied by industrial designers Pierre and Gui, Alex
                wondered how to help the 8 million French insomniacs succeed in
                falling asleep faster thanks to breathing, without having to
                train during long months. After 2 years of research and numerous
                prototypes, Dodow was born.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Created;
