import React from "react";
import "./easy.css";
import easy1 from "./easy1.mp4";
import easy2 from "./easy2.mp4";
import easy3 from "./easy3.mp4";

function Easy() {
  return (
    <div className="Wrap--light snipcss-5Jjhg snip-div" id="howitworks">
      <section className="Container Steps flex-col snip-section">
        <header>
          <h2 className="Typo--h2 snip-h2">Easy as 1, 2, 3!</h2>
        </header>
        <div className="Steps--group flex-col snip-div">
          <div className="Steps--element flex-col snip-div">
            <video
              autoPlay
              muted
              loop
              playsInline
              className="Steps--element-video autoplay"
              loading="lazy"
              poster="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow2021-step1-video-poster.jpg"
            >
              <source src={easy1} type="video/mp4" />
            </video>
            <div className="Steps--element-text snip-div">
              <strong className="Typo--h3">1. Inhale</strong>
              <p className="Typo--body snip-p">
                Tap the touch-sensitive surface once for the 8-minute mode,
                twice for the 20-minute mode. A blue light appears on the
                ceiling. Gently synchronize your breathing: Inhale when the
                light expands...
              </p>
            </div>
          </div>
          <div className="Steps--element 2 flex-col snip-div">
            <video
              autoPlay
              muted
              loop
              playsInline
              className="Steps--element-video autoplay"
              loading="lazy"
              poster="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow2021-step2-video-poster.jpg"
            >
              <source src={easy2} type="video/mp4" />
            </video>
            <div className="Steps--element-text snip-div">
              <strong className="Typo--h3">2. Exhale</strong>
              <p className="Typo--body snip-p">
                ...then exhale when the light retracts. You may notice that the
                exhalation is deliberately longer. This will help create a
                sensation of tiredness which is conductive to falling asleep.
              </p>
            </div>
          </div>
          <div className="Steps--element flex-col snip-div">
            <video
              autoPlay
              muted
              loop
              playsInline
              className="Steps--element-video autoplay"
              loading="lazy"
              poster="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow2021-step3-video-poster.jpg"
            >
              <source src={easy3} type="video/mp4" />
            </video>
            <div className="Steps--element-text snip-div">
              <strong className="Typo--h3">3. Sleep</strong>
              <p className="Typo--body snip-p">
                At the end of the exercise (8 minutes or 20 minutes) Dodow
                switches itself off. You'll be in the ideal body state to fall
                asleep naturally, in whatever position you like.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Easy;
