import React from "react";
import "./first.css";

export default function First() {
  const toggle=()=>{
                document.querySelector(".Header--wrap-nav-toggle").classList.toggle("snip-button");
                document.querySelector(".Header--wrap-mobile").classList.toggle("snip-nav");
                document.getElementsByTagName("html")[0].classList.toggle("nav-html");
  }
  return (
    <div>


      <header className="Header Header--bundles flex-col snipcss-RXRns ">
        <div className="Header--wrap ">
          <nav className="Header--wrap-nav snip-nav">
            <a
              href="https://www.mydodow.com/dodow/en-us/bundles"
              className="Flex Flex--column Flex--Center snip-a"
            >
              <img
                src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/logo_dodow_white.svg"
                className="Header--logo snip-img"
                alt="header logo"
                loading="lazy"
              />
            </a>
            <button
              className="Header--wrap-nav-toggle"
              aria-label="Open menu"
              data-label-open="Open menu"
              data-label-close="Close menu"
            onClick={toggle}
            ></button>
            <ul className="Header--wrap-nav-list snip-ul">
              <li className="Header--wrap-nav-item snip-li">
                <a
                  href="#howitworks"
                  id="desktop-nav-steps-link"
                  className="Header--wrap-nav-item-link Typo--navbar snip-a"
                  
                >
                  How does it work?
                </a>
              </li>
              <li className="Header--wrap-nav-item snip-li">
                <a
                  href="#cards"
                  id="desktop-nav-cards-link"
                  className="Header--wrap-nav-item-link Typo--navbar snip-a"
                >
                  Who is this for?
                </a>
              </li>
              <li className="Header--wrap-nav-item snip-li">
                <a
                  href="#questions"
                  id="desktop-nav-questions-link"
                  className="Header--wrap-nav-item-link Typo--navbar snip-a"
                >
                  Questions
                </a>
              </li>
              <li className="Header--wrap-nav-item snip-li">
                <a
                  href="#reviews"
                  id="desktop-nav-reviews-link"
                  className="Header--wrap-nav-item-link Typo--navbar snip-a"
                >
                  Reviews
                </a>
              </li>
              <li className="Header--wrap-nav-item snip-li">
                <a
                  href="#bundle"
                  className="Typo--navbar Button Button--small Button--contrast-green snip-a"
                  id="desktop-nav-buy-btn"
                >
                  Buy Dodow
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="Header--wrap-mobile ">
          <nav className="Header--wrap-mobile-nav ">
            <ul className="Header--wrap-mobile-nav-list ">
              <li className="Header--wrap-mobile-nav-item snip-li">
                <a
                  href="#howitworks"
                  id="mobile-nav-steps-link"
                  className="Header--wrap-mobile-nav-item-link Typo--navbar snip-a"
                  onClick={toggle}
                >
                  How does it work?
                </a>
              </li>
              <li className="Header--wrap-mobile-nav-item snip-li">
                <a
                  href="#cards"
                  id="mobile-nav-cards-link"
                  className="Header--wrap-mobile-nav-item-link Typo--navbar snip-a"
                  onClick={toggle}
                >
                  Who is this for?
                </a>
              </li>
              <li className="Header--wrap-mobile-nav-item snip-li">
                <a
                  href="#questions"
                  id="mobile-nav-questions-link"
                  className="Header--wrap-mobile-nav-item-link Typo--navbar snip-a"
                  onClick={toggle}
                >
                  Questions
                </a>
              </li>
              <li className="Header--wrap-mobile-nav-item snip-li">
                <a
                  href="#reviews"
                  id="mobile-nav-reviews-link"
                  className="Header--wrap-mobile-nav-item-link Typo--navbar snip-a"
                  onClick={toggle}
                >
                  Reviews
                </a>
              </li>
            </ul>
            <a
              href="#bundle"
              className="Typo--button Button-buy Button Button--contrast-green snip-a"
              id="mobile-nav-buy-btn"
              onClick={toggle}
            >
              <img
                src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/icon_dodow_green.svg"
                alt=""
                loading="lazy"
                className="snip-img"
                onClick={toggle}
              />
              <span>Buy Dodow</span>
            </a>
          </nav>
        </div>
        <div className="Container Container--flex flex-col ">
          <h1 className="Header--title Typo--h1 snip-h1">
            Breathe in, breathe out, sleep
          </h1>
          <a href="#bundle" className="buy-btn-a snip-a">
            <button
              className="Typo--button Button Button--default Button--icon "
              id="buy-btn-hero-desktop"
            >
              Buy Dodow
            </button>
          </a>
          <div className="Header--total-reviews snip-div">
            <ul className="snip-ul">
              <li className="snip-li">
                <i className="Header--total-reviews-star checked"></i>
              </li>
              <li className="snip-li">
                <i className="Header--total-reviews-star checked"></i>
              </li>
              <li className="snip-li">
                <i className="Header--total-reviews-star checked"></i>
              </li>
              <li className="snip-li">
                <i className="Header--total-reviews-star checked"></i>
              </li>
              <li className="snip-li">
                <i className="Header--total-reviews-star half absolute"></i>
                <i className="Header--total-reviews-star absolute"></i>
              </li>
            </ul>
            <div className="Header--total-reviews-caption Typo--h4 snip-div">
              5845 Amazon &amp; Google reviews
            </div>
          </div>
        </div>
        <div className="Header--logos-line flex-row">
          <img
            src="https://d3ce15loqxej0z.cloudfront.net/img/bundle/presslogos-US.svg"
            className="Header--logos-line-image Header--logos-line-image-1 snip-img"
            alt="bandeau"
            loading="lazy"
          />
        </div>
      </header>
    </div>
  );
}
