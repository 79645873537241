import React,{useRef} from "react";
import "./users.css";

export default function Users() {

  const img_div=useRef(null);

  const active_height=window.innerHeight*4.3;
  window.onscroll = function(e) {
   if(window.scrollY > active_height && img_div.current.style.opacity!==1){
     img_div.current.style.opacity=1
   }

   }
  
  return (
    <div>
      <section className="Counter">
        <img
          className="Counter--background img-fluid"
          src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/counter_bg_mobile_768.jpg"
          srcSet="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/bg_counter_desktop_768.jpg 768w, https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/bg_counter_desktop_800.jpg 800w, https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/bg_counter_desktop_1120.jpg 1120w, https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/bg_counter_desktop_1440.jpg 1440w, "
          sizes="100%"
          alt=""
          loading="lazy"
        />
        <div className="Counter--text-wrap flex-col">
          <header>
            <p className="Counter--users Typo--counter-label">Number of users</p>
            <p className="Counter--number Typo--counter-count">1,001,278</p>
          </header>
          <div className="Counter--bubble flex-row" ref={img_div}>
            <img
              className="Counter--bubble-img"
              src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow2021-bubble-packshot1.jpg"
              alt=""
            />
            <p>
              <span className="Counter--bubble-user Typo--counter-customer">
                Kathleen from Homer{" "}
              </span>
              <strong className="Counter--bubble-order Typo--counter-order">
                recently bought Dodow
              </strong>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
