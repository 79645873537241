import Carousel from "react-bootstrap/Carousel";
import IMAGES from "./imgs/imgs";
import "./carousel_img.css";

const Carousel_img = () => {
  return (
    <div>
      <div
        id="img_crousel"
        className="carousel slide size"
        data-bs-ride="carousel"
        data-bs-touch="true"
        data-bs-interval={5000}
      >
        <div className="carousel-indicators ">
          <button
            type="button"
            data-bs-target="#img_crousel"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#img_crousel"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#img_crousel"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
          <button
            type="button"
            data-bs-target="#img_crousel"
            data-bs-slide-to="3"
            aria-label="Slide 4"
          ></button>
          <button
            type="button"
            data-bs-target="#img_crousel"
            data-bs-slide-to="4"
            aria-label="Slide 5"
          ></button>
        </div>
        <div className="carousel-inner ">
          {IMAGES.map(({ img }, index) => {
            return (
              <div
                className={
                  index === 0 ? "carousel-item active" : "carousel-item"
                }
                key={index}
              >
                <img src={img} className="d-block w-100 setting" alt="..." />
              </div>
            );
          })}
        </div>
        <button
          className="carousel-control-prev img-carousel-control"
          type="button"
          data-bs-target="#img_crousel"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon img-control-prev-icon "
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next img-carousel-control"
          type="button"
          data-bs-target="#img_crousel"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon img-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Carousel_img;
