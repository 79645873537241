import React from "react";
import Carousel from "react-bootstrap/Carousel";
function Second_test() {
  return (
    <div className="Media--wrap" data-bs-ride="carousel">
    <div
        id="carouselExampleIndicators"
        className="carousel slide second-carousel "
        data-bs-ride="carousel"

      >
        <div className="carousel-indicators second-carousel-indicators ">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
               <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="3"
            aria-label="Slide 4"
          ></button>
               <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="4"
            aria-label="Slide 5"
          ></button>
                     <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="5"
            aria-label="Slide 6"
          ></button>
                     <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="6"
            aria-label="Slide 7"
          ></button>
        </div>
        <div className="carousel-inner h-100">
          <div className="carousel-item  active">
          <figure
                className="Flex flex-column h-100 justify-content-between p-4"
                id="media-carousel-slide01"
                style={{ width: "calc(100%)" }}
                tabIndex="0"
              >
                <blockquote className="Typo--h3 Flex Flex--center">
                  “Whether you desperately need help settling down one night or
                  want to add to your daily wind-down ritual, this quirky gadget
                  might be your solution [...] We tried the Dodow ourselves and
                  it helped calm us down and get better sleep.”
                </blockquote>
                <figcaption>
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow-media-cnn.svg"
                    loading="lazy"
                    alt="CNN"
                  />
                </figcaption>
              </figure>
          </div>
          <div className="carousel-item ">
          <figure
                 className="Flex flex-column h-100 justify-content-between p-4"
                id="media-carousel-slide02"
                style={{ width: "calc(100%)" }}
                aria-hidden="true"
              >
                <blockquote className="Typo--h3 Flex Flex--center">
                  "Avoid spending hundreds of dollars on other remedies with
                  Dodow, a simple but effective sleep trainer."
                </blockquote>
                <figcaption>
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow-media-forbes.svg"
                    loading="lazy"
                    alt="Forbes"
                  />
                </figcaption>
              </figure>
          </div>
          <div className="carousel-item ">
            <figure
                className="Flex flex-column h-100 justify-content-between p-4"
                id="media-carousel-slide03"
                style={{ width: "calc(100%)" }}
                aria-hidden="true"
              >
                <blockquote className="Typo--h3 Flex Flex--center">
                  “Dodow is a French company with a fresh take on sleep aids.”
                </blockquote>
                <figcaption>
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow-media-businessinsider.svg"
                    loading="lazy"
                    alt="BusinessInsider"
                  />
                </figcaption>
              </figure>
          </div>

          <div className="carousel-item ">
          <figure
            className="Flex flex-column h-100 justify-content-between p-4"
                id="media-carousel-slide04"
                style={{ width: "calc(100%)" }}
                aria-hidden="true"
              >
                <blockquote className="Typo--h3 Flex Flex--center">
                  “Strangely enough, it's possible that you may need to re-learn
                  how to sleep. Your saving grace comes in the form of a
                  metronome and light system by Dodow that teaches you how to
                  fall asleep naturally — no Zzzquil needed.”
                </blockquote>
                <figcaption>
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow-media-mashable.svg"
                    loading="lazy"
                    alt="Mashable"
                  />
                </figcaption>
              </figure>
        </div>
        <div className="carousel-item ">
        <figure
              className="Flex flex-column h-100 justify-content-between p-4"
                id="media-carousel-slide05"
                style={{ width: "calc(100%)" }}
                aria-hidden="true"
              >
                <blockquote className="Typo--h3 Flex Flex--center">
                  "The effect is hypnotic and even more effective than a
                  sleeping pill."
                </blockquote>
                <figcaption>
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow-media-vogue.svg"
                    loading="lazy"
                    alt="Vogue"
                  />
                </figcaption>
              </figure>
        </div>
        <div className="carousel-item ">
        <figure
             className="Flex flex-column h-100 justify-content-between p-4"
                id="media-carousel-slide06"
                style={{ width: "calc(100%)" }}
                aria-hidden="true"
              >
                <blockquote className="Typo--h3 Flex Flex--center">
                  "It projects a calming blue light, which works as a guide for
                  your breathing, and helps you to fall asleep naturally, and on
                  average 2.5 times faster than on your own."
                </blockquote>
                <figcaption>
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow-media-buzzfeed.svg"
                    loading="lazy"
                    alt="BuzzFeed "
                  />
                </figcaption>
              </figure>
        </div>
        <div className="carousel-item ">
        <figure
             className="Flex flex-column h-100 justify-content-between p-4"
                id="media-carousel-slide07"
                style={{ width: "calc(100%)" }}
                aria-hidden="true"
              >
                <blockquote className="Typo--h3 Flex Flex--center">
                  “At the end of the 8 or 20 minute cycle, the sleep aid shuts
                  off automatically, leaving you in the perfect place to fall
                  asleep.”
                </blockquote>
                <figcaption>
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow-media-britco.svg"
                    loading="lazy"
                    alt="BritCo"
                  />
                </figcaption>
              </figure>
        </div>



        </div>
        <button
          className="carousel-control-prev second-control"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon second-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next second-control"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon second-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
        <div>
                {/* <Carousel >
        <Carousel.Item  className="">
          
            <figure
                className="Flex flex-column h-100 justify-content-between p-4"
                id="media-carousel-slide01"
                style={{ width: "calc(100%)" }}
                tabIndex="0"
              >
                <blockquote className="Typo--h3 Flex Flex--center">
                  “Whether you desperately need help settling down one night or
                  want to add to your daily wind-down ritual, this quirky gadget
                  might be your solution [...] We tried the Dodow ourselves and
                  it helped calm us down and get better sleep.”
                </blockquote>
                <figcaption>
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow-media-cnn.svg"
                    loading="lazy"
                    alt="CNN"
                  />
                </figcaption>
              </figure>
     
     
        </Carousel.Item>
        <Carousel.Item>
        <figure
                 className="Flex flex-column h-100 justify-content-between p-4"
                id="media-carousel-slide02"
                style={{ width: "calc(100%)" }}
                aria-hidden="true"
              >
                <blockquote className="Typo--h3 Flex Flex--center">
                  "Avoid spending hundreds of dollars on other remedies with
                  Dodow, a simple but effective sleep trainer."
                </blockquote>
                <figcaption>
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow-media-forbes.svg"
                    loading="lazy"
                    alt="Forbes"
                  />
                </figcaption>
              </figure>
        </Carousel.Item>
        <Carousel.Item>
        <figure
                className="Flex flex-column h-100 justify-content-between p-4"
                id="media-carousel-slide03"
                style={{ width: "calc(100%)" }}
                aria-hidden="true"
              >
                <blockquote className="Typo--h3 Flex Flex--center">
                  “Dodow is a French company with a fresh take on sleep aids.”
                </blockquote>
                <figcaption>
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow-media-businessinsider.svg"
                    loading="lazy"
                    alt="BusinessInsider"
                  />
                </figcaption>
              </figure>
        
        </Carousel.Item>
        <Carousel.Item>
        <figure
            className="Flex flex-column h-100 justify-content-between p-4"
                id="media-carousel-slide04"
                style={{ width: "calc(100%)" }}
                aria-hidden="true"
              >
                <blockquote className="Typo--h3 Flex Flex--center">
                  “Strangely enough, it's possible that you may need to re-learn
                  how to sleep. Your saving grace comes in the form of a
                  metronome and light system by Dodow that teaches you how to
                  fall asleep naturally — no Zzzquil needed.”
                </blockquote>
                <figcaption>
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow-media-mashable.svg"
                    loading="lazy"
                    alt="Mashable"
                  />
                </figcaption>
              </figure>
        
        </Carousel.Item>
        <Carousel.Item>
        <figure
              className="Flex flex-column h-100 justify-content-between p-4"
                id="media-carousel-slide05"
                style={{ width: "calc(100%)" }}
                aria-hidden="true"
              >
                <blockquote className="Typo--h3 Flex Flex--center">
                  "The effect is hypnotic and even more effective than a
                  sleeping pill."
                </blockquote>
                <figcaption>
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow-media-vogue.svg"
                    loading="lazy"
                    alt="Vogue"
                  />
                </figcaption>
              </figure>
        
        </Carousel.Item>
        <Carousel.Item>
        <figure
             className="Flex flex-column h-100 justify-content-between p-4"
                id="media-carousel-slide06"
                style={{ width: "calc(100%)" }}
                aria-hidden="true"
              >
                <blockquote className="Typo--h3 Flex Flex--center">
                  "It projects a calming blue light, which works as a guide for
                  your breathing, and helps you to fall asleep naturally, and on
                  average 2.5 times faster than on your own."
                </blockquote>
                <figcaption>
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow-media-buzzfeed.svg"
                    loading="lazy"
                    alt="BuzzFeed "
                  />
                </figcaption>
              </figure>
        
        </Carousel.Item>
        <Carousel.Item>
        <figure
             className="Flex flex-column h-100 justify-content-between p-4"
                id="media-carousel-slide07"
                style={{ width: "calc(100%)" }}
                aria-hidden="true"
              >
                <blockquote className="Typo--h3 Flex Flex--center">
                  “At the end of the 8 or 20 minute cycle, the sleep aid shuts
                  off automatically, leaving you in the perfect place to fall
                  asleep.”
                </blockquote>
                <figcaption>
                  <img
                    src="https://d3ce15loqxej0z.cloudfront.net/img/dodow2021/dodow-media-britco.svg"
                    loading="lazy"
                    alt="BritCo"
                  />
                </figcaption>
              </figure>
        
        </Carousel.Item>
      </Carousel> */}
 
        </div>
      </div>
    </div>
  


  );
}

export default Second_test;
